// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import useSWR from "swr";
import { useEffect, useState, useRef } from "react";

import { fetchData } from "utils/clientFunctions";

import { useNavigate, useParams, Navigate, Link } from "react-router-dom";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useFormik, Field, Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import { CardTopBoxBack } from "layouts/commonComponents/cardTopBoxBack";
import DataTable from "react-data-table-component";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { firstLetterCapital } from "utils/clientFunctions";
import { dateFormat } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import { CSVDownload, CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { dateTimeStamp } from "utils/clientFunctions";
function CandidatePlacementReport() {
  const csvLink = useRef();
  const getFetchData = async (url) => {
    return await fetchData(url);
  };
  const [searchFormData, setSearchFormData] = useState({
    deployment_status: "",
  });
  const [report, setReport] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [deploymentTypeList, setDeploymentTypeList] = useState([]);
  useEffect(() => {
    getReport();
  }, [pageIndex, searchFormData]);

  const getReport = async () => {
    let queryUrl = getQueryParams();
    getFetchData(`report/candidate_placement${queryUrl}`).then((res) => {
      if (res && res.data) {
        setPageCount(Math.ceil(res.count / 10));
        setReport(res.data || []);
      }
    });
  };

  const columns = [
    {
      name: "Area",
      selector: (row) => row.area,
    },
    {
      name: "Applicant Name ",
      selector: (row) =>
        row.salutation + " " + row.first_name + " " + row.last_name,
    },
    {
      name: "Passport No",
      selector: (row) => row.passport_number,
    },
    {
      name: "Country",
      selector: (row) => row.country_info?.name,
    },
    {
      name: "DOB",
      selector: (row) =>
        row.date_of_birth ? dateFormat(row.date_of_birth) : "-",
    },
    {
      name: "Visa Issue Date",
      selector: (row) =>
        row.deployment_visa_issue_date
          ? dateFormat(row.deployment_visa_issue_date)
          : "-",
    },
    {
      name: "Visa Exp Date ",
      selector: (row) =>
        row.deployment_visa_expiry_date
          ? dateFormat(row.deployment_visa_expiry_date)
          : "-",
    },
    {
      name: "Deployment Date ",
      selector: (row) =>
        row.deployment_date ? dateFormat(row.deployment_date) : "-",
    },
  ];
  const data = report;
  const doPaginationChange = (event, value) => {
    setPageIndex(value);
  };

  const [csvData, setCsvData] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);
  let csvHeaders = [
    { label: "Area", key: "area" },
    { label: "Applicant Name", key: "applicant_name" },
    { label: "Passport Number", key: "passport_number" },
    { label: "Country", key: "country" },
    { label: "DOB", key: "date_of_birth" },
    { label: "Visa Issue Date", key: "deployment_visa_issue_date" },
    { label: "Visa Exp Date", key: "deployment_visa_expiry_date" },
    { label: "Deployment Date", key: "deployment_date" },
  ];
  const getQueryParams = () => {
    let queryUrl = `?page=${pageIndex}`;
    queryUrl = searchFormData.deployment_status?.setting_id
      ? queryUrl +
        "&deployment_status=" +
        searchFormData.deployment_status?.setting_id
      : queryUrl;
    return queryUrl;
  };

  const exportCsv = async () => {
    let queryUrl = getQueryParams();
    getFetchData(`report/candidate_placement_download${queryUrl}`).then(
      async (res) => {
        if (res?.data) {
          let exportData = [];
          await Promise.all(
            res?.data.map((report) => {
              report.applicant_name =
                report.salutation +
                " " +
                report.first_name +
                " " +
                report.last_name;

              report.country = report.country_info?.name;

              report.date_of_birth = report.date_of_birth
                ? dateFormat(report.date_of_birth)
                : "-";

              report.deployment_visa_issue_date =
                report.deployment_visa_issue_date
                  ? dateFormat(report.deployment_visa_issue_date)
                  : "-";
              report.deployment_visa_expiry_date =
                report.deployment_visa_expiry_date
                  ? dateFormat(report.deployment_visa_expiry_date)
                  : "-";
              report.deployment_date = report.deployment_date
                ? dateFormat(report.deployment_date)
                : "-";

              exportData.push(report);
            })
          );
          setCsvData(exportData); //Set the csv data
          setDownloadCsv(true); // Show the CSVDwonload link
          setTimeout(() => {
            csvLink?.current?.link?.click();
            setDownloadCsv(false); // Hide the CSVDwonload link
          }, 100);
        }
      }
    );
  };

  useEffect(() => {
    const deploymentTypeUrl = `setting/type/16`;
    getFetchData(deploymentTypeUrl).then((res) => {
      setDeploymentTypeList(res.data);
    });
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <CardTopBoxBack
                title={"Candidate Placement Report"}
                showBackButton={false}
              />
              <MDBox
                variant="contained"
                bgColor="white"
                color="black"
                borderRadius="md"
                shadow="md"
              >
                <Grid container p={2} spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Formik>
                      <Form>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={3}></Grid>
                          <Grid item xs={6} md={3}>
                            <MDBox mb={2} className="mdsAutocompleteGrid">
                              <Autocomplete
                                value={searchFormData.deployment_type_id}
                                size="small"
                                name="deployment_status"
                                options={deploymentTypeList}
                                getOptionLabel={(deploymentTypeList) =>
                                  deploymentTypeList.setting_value || ""
                                }
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    setSearchFormData({
                                      ...searchFormData,
                                      ["deployment_status"]: newValue,
                                    });
                                  } else {
                                    setSearchFormData({
                                      ...searchFormData,
                                      ["deployment_status"]: "",
                                    });
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Deployment Status"
                                    InputLabelProps={{ shrink: true }}
                                  />
                                )}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            {/* {downloadCsv && (
                              <>
                                <CSVDownload
                                  data={csvData}
                                  filename={"my-3534564-file.csv"}
                                  headers={csvHeaders}
                                  target="_self"
                                />
                              </>
                            )} */}
                            <CSVLink
                              className=" d-none "
                              data={csvData}
                              filename={`Candidate Placement Report ${dateTimeStamp()}`}
                              headers={csvHeaders}
                              ref={csvLink}
                              target="_self"
                            />
                            <MDBox display="flex" alignItems="center">
                              <MDButton
                                variant="gradient"
                                color="primary"
                                size="small"
                                type="button"
                                className="saveButton"
                                onClick={exportCsv}
                              >
                                <DownloadIcon /> Export CSV
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Form>
                    </Formik>
                    <DataTable columns={columns} data={data} />
                    <Grid item xs={12} md={12}>
                      <Stack spacing={2} mb={2} ml={2}>
                        <Pagination
                          count={pageCount}
                          page={pageIndex}
                          onChange={doPaginationChange}
                          variant="outlined"
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CandidatePlacementReport;
