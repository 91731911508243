// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Settings from "layouts/settings";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import CreateRa from "layouts/recruiting_agency/create";
import UpdateRa from "layouts/recruiting_agency/update";
import RaList from "layouts/recruiting_agency/list";
import CompanyList from "layouts/company/list";
import ApplicantsList from "layouts/applicant/list";
import StaffsList from "layouts/staffs/list";

// @mui icons
import Icon from "@mui/material/Icon";
import LeadsList from "layouts/leads/list";
import TasksList from "layouts/tasks/list";
import AccountsList from "layouts/accounts/list";
import MeetingsList from "layouts/meetings/list";
import DealsList from "layouts/deals/list";
import CallReport from "layouts/call_report/save";
import CallReportList from "layouts/call_report/list";
import BookingList from "layouts/booking/list";
import InterviewList from "layouts/interview/list";
import AttestationList from "layouts/attestation/list";
import DeploymentList from "layouts/deployment/list";
import TaxList from "layouts/tax/list";
import InvoiceList from "layouts/invoice/list";
import RevenueReport from "layouts/reports/revenue";
import ExpenseReport from "layouts/reports/expense";
import ClientGrowthReport from "layouts/reports/client_growth";
import CandidatePlacementReport from "layouts/reports/candidate_placement";

const sideBarRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    subMenu: ["dashboard"],
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    isDisplayed: true,
  },
  {
    type: "collapse",
    name: "Applicants",
    subMenu: ["applicant", "save_applicant"],
    key: "applicant",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/applicant",
    component: <ApplicantsList />,
    isDisplayed: true,
  },
  {
    type: "collapse",
    name: "Company",
    subMenu: ["company_list", "create_company"],
    key: "company_list",
    icon: <Icon fontSize="small">business</Icon>,
    route: "/company_list",
    component: <CompanyList />,
    isDisplayed: true,
  },
  {
    type: "collapse",
    name: "Recruiting Agency",
    subMenu: ["ra_list", "create_ra", "update_ra"],
    key: "ra_list",
    icon: <Icon fontSize="small">reduce_capacity</Icon>,
    route: "/ra_list",
    component: <RaList />,
    isDisplayed: true,
  },
  {
    type: "collapse",
    name: "Call Report",
    subMenu: ["call_report_list", "call_report"],
    key: "call_report_list",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/call_report_list",
    component: <CallReportList />,
    isDisplayed: true,
  },
  {
    type: "collapse",
    name: "Leads",
    subMenu: ["lead", "leaddata"],
    key: "lead",
    icon: <Icon fontSize="small">add_task</Icon>,
    route: "/lead",
    component: <LeadsList />,
    isDisplayed: true,
  },
  {
    type: "collapse",
    name: "Meetings",
    subMenu: ["meeting", "meetingdata"],
    key: "meeting",
    icon: <Icon fontSize="small">videocam</Icon>,
    route: "/meeting",
    component: <MeetingsList />,
    isDisplayed: true,
  },
  {
    type: "collapse",
    name: "Deals",
    subMenu: ["deal", "dealdata"],
    key: "deal",
    icon: <Icon fontSize="small">local_offer</Icon>,
    route: "/deal",
    component: <DealsList />,
    isDisplayed: true,
  },
  {
    type: "collapse",
    name: "Attestation",
    subMenu: ["attestation_list", "attestation"],
    key: "attestation_list",
    icon: <Icon fontSize="small">attach_file</Icon>,
    route: "/attestation_list",
    component: <AttestationList />,
    isDisplayed: true,
  },
  {
    type: "collapse",
    name: "Interview",
    subMenu: ["interview_list", "interview"],
    key: "interview_list",
    icon: <Icon fontSize="small">school</Icon>,
    route: "/interview_list",
    component: <InterviewList />,
    isDisplayed: true,
  },
  {
    type: "collapse",
    name: "Booking",
    subMenu: ["booking_list", "booking"],
    key: "booking_list",
    icon: <Icon fontSize="small">book_online</Icon>,
    route: "/booking_list",
    component: <BookingList />,
    isDisplayed: true,
  },
  // {
  //   type: "collapse",
  //   name: "Deployment",
  //   subMenu: ["deployment_list", "deployment"],
  //   key: "deployment_list",
  //   icon: <Icon fontSize="small">storage</Icon>,
  //   route: "/deployment_list",
  //   component: <DeploymentList />,
  //   isDisplayed: false,
  // },
  {
    type: "collapse",
    name: "Invoice",
    subMenu: ["invoice_list", "invoice"],
    key: "invoice_list",
    icon: <Icon fontSize="small">description</Icon>,
    route: "/invoice_list",
    component: <InvoiceList />,
    isDisplayed: true,
  },
  {
    type: "collapse",
    name: "Tax",
    subMenu: ["tax_list", "tax"],
    key: "tax_list",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tax_list",
    component: <TaxList />,
    isDisplayed: true,
  },
  {
    type: "collapse",
    name: "Accounts",
    subMenu: ["account", "accountdata"],
    key: "account",
    icon: <Icon fontSize="small">account_balance_wallet</Icon>,
    route: "/account",
    component: <AccountsList />,
    isDisplayed: true,
  },

  {
    type: "collapse",
    name: "Tasks",
    subMenu: ["task", "taskdata"],
    key: "task",
    icon: <Icon fontSize="small">task</Icon>,
    route: "/task",
    component: <TasksList />,
    isDisplayed: true,
  },
  {
    type: "dropdown",
    name: "Reports",
    dropdown: [
      {
        name: "Revenue",
        route: "/revenue_report",
      },
      {
        name: "Expense",
        route: "/expense_report",
      },
      {
        name: "Client Growth",
        route: "/client_growth_report",
      },
      {
        name: "Candidate Placement",
        route: "/candidate_placement_report",
      },
      {
        name: "Credit Note",
        route: "/credit_note",
      },
    ],
    subMenu: [],
    key: "",
    icon: <Icon fontSize="small">report</Icon>,
    route: "#",
    component: <RevenueReport />,
    isDisplayed: true,
  },
  {
    type: "collapse",
    name: "Users",
    subMenu: ["staffs", "staffdata"],
    key: "staffs",
    icon: <Icon fontSize="small">person3</Icon>,
    route: "/staffs",
    component: <StaffsList />,
    isDisplayed: true,
  },
  {
    type: "collapse",
    name: "Settings",
    subMenu: ["settings"],
    key: "settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/settings",
    component: <Settings />,
    isDisplayed: true,
  },
];

export default sideBarRoutes;
