import * as Yup from "yup";

export const attestationSchema = Yup.object({
  attestation_date: Yup.date().required("Please provide attestation date"),
  vendor_type: Yup.mixed().required("Please select vendor type"),
  vendor_type_id: Yup.mixed().required("Please select vendor name"),
  attestation_type_id: Yup.mixed().required("Please select attestation type"),
  currency_id: Yup.mixed().required("Please select currency"),
  attestation_fees: Yup.number()
    .typeError("Enter valid attestation fees")
    .required("Please provide attestation fees"),
  paying_type: Yup.mixed().required("Please select who is paying"),
  status_id: Yup.mixed().required("Please select status"),
  comment: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter remarks")
    .max(250, "Max 250 characters allowed"),
});
