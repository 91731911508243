// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import useSWR from "swr";
import { useEffect, useState } from "react";
//import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Tooltip,
} from "@mui/material";
//import { settingDefault } from "utils/clientFunctions";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import * as Yup from "yup";
import { updateData } from "utils/clientFunctions";
import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";

import { postData, doUpload } from "utils/clientFunctions";
import { ToastProvider, useToasts } from "react-toast-notifications";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import { useNavigate, useParams, Navigate, Link } from "react-router-dom";
import MDAvatar from "components/MDAvatar";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../assets/css/Custom.css";
import { useFormik } from "formik";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import { CardTopBoxBack } from "layouts/commonComponents/cardTopBoxBack";
import { ProfileSchema } from "./components/schemas";
import { updatePasswordScheme } from "./components/schemas/updatePassword";
import { removeDuplicates } from "utils/clientFunctions";

function Profile() {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [companys, setCompanys] = useState([]);
  //Profile data
  const [formData, setFormData] = useState({
    user_id: "",
    user_type: "",
    first_name: "",
    last_name: "",
    mobile_number: "",
    profile_pic: "",
  });

  const [updatePasswordFormData, setUpdatePasswordFormData] = useState({
    old_password: "",
    password: "",
    confirm_password: "",
  });

  const getPostData = async (url) => {
    return await postData(url);
  };
  const getFetchData = async (url) => {
    return await fetchData(url);
  };

  const getProfile = async () => {
    getPostData("user/by_token").then((res) => {
      if (res.data) {
        let {
          user_id,
          user_type,
          first_name,
          last_name,
          mobile_number,
          email_id,
          profile_pic,
          company_list,
        } = res.data;

        setFormData({
          user_id,
          first_name,
          last_name,
          mobile_number,
          email_id,
          profile_pic: profile_pic || "",
        });

        let companyList = [];
        company_list?.map((company) => {
          const {
            user_company_id,
            name,
            address,
            landline_code_id,
            landline_number,
            mobile_code_id,
            mobile_number,
            whatapp_code_id,
            whatapp_number,
            email_id,
            website,
            logo,
            currency_id,
            bank_name,
            branch_name,
            bank_account_number,
            bank_ifsc_code,
            iban_number,
            vat_gst_number,
            swift_code,
            landline_code_info,
            mobile_code_info,
            whatapp_code_info,
            currency_info,
            terms_condition,
            invoice_prefix,
            invoice_suffix,
            tax_type,
          } = company;
          let newTaxYype = {
            label: tax_type,
            value: tax_type,
          };
          companyList.push({
            user_company_id,
            name,
            address,
            landline_code_id: landline_code_info,
            landline_number,
            mobile_code_id: mobile_code_info,
            mobile_number,
            whatapp_code_id: whatapp_code_info,
            whatapp_number,
            email_id,
            website,
            logo,
            currency_id: currency_info,
            bank_name,
            branch_name,
            bank_account_number,
            bank_ifsc_code,
            iban_number,
            vat_gst_number,
            swift_code,
            terms_condition,
            invoice_prefix,
            invoice_suffix,
            tax_type: newTaxYype,
          });
        });
        setCompanys(companyList);
      }
    });
  };

  useEffect(() => {
    getProfile();
    //Get countries list
    const countryUrl = `country/list`;
    getFetchData(countryUrl).then((res) => {
      let countryList = res.data;
      setCountryList(countryList);
      let currencyList = removeDuplicates(countryList, "currency_code"); //countryList?.filter((cr)=>cr.currency_code != "")
      setCurrencyList(currencyList);
    });
  }, []);

  //Update password submit
  const handleUpdatePasswordFormSubmit = async (data) => {
    const taxUrl = `user/update_password`;
    const response = await postData(taxUrl, data);

    if (!response.status) {
      if (response.message) {
        addToast(response.message, { appearance: "error", autoDismiss: true });
      }
      if (response.errors) {
        let apiErrors = response.errors;
        for (let i = 0; i < apiErrors.length; i++) {
          let errorMessage = apiErrors[i].message;
          errorMessage = errorMessage.replace(/['"]+/g, "").replace(/_/g, " ");
          errorMessage =
            errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
          addToast(errorMessage, { appearance: "error", autoDismiss: true });
        }
      }
    } else {
      addToast("Password has been updated successfully", {
        appearance: "success",
        autoDismiss: true,
      });
      //navigate(-1);
    }
  };

  //Update password formik const
  const {
    values: upValues,
    errors: upErrors,
    touched: upTouched,
    handleBlur: handleUpBlur,
    handleChange: handleUpChange,
    handleSubmit: handleUpdatePasswordSubmit,
  } = useFormik({
    initialValues: updatePasswordFormData,
    enableReinitialize: true,
    validationSchema: updatePasswordScheme,
    onSubmit: (values, { resetForm }) => {
      handleUpdatePasswordFormSubmit(values);
      resetForm({ upValues: "" });
    },
  });

  //Update Profile submit code
  const handleFormSubmit = async (data, resetForm) => {
    // console.log("companys", companys);
    // return false;

    delete data.user_id; //Remove user_id from profile data
    delete data.email_id; //Remove email id from profile data

    let submitData = {};
    submitData = data;

    //Convert companies into array
    let allCompanys = [];
    if (companys.length) {
      companys?.map((company) => {
        const {
          user_company_id,
          name,
          address,
          landline_code_id,
          landline_number,
          mobile_code_id,
          mobile_number,
          whatapp_code_id,
          whatapp_number,
          email_id,
          website,
          logo,
          currency_id,
          bank_name,
          branch_name,
          bank_account_number,
          bank_ifsc_code,
          iban_number,
          vat_gst_number,
          swift_code,
          terms_condition,
          invoice_prefix,
          invoice_suffix,
          tax_type,
        } = company;
        if (name) {
          allCompanys.push({
            user_company_id,
            name,
            address,
            landline_code_id: landline_code_id?.country_id,
            landline_number,
            mobile_code_id: mobile_code_id?.country_id,
            mobile_number,
            whatapp_code_id: whatapp_code_id?.country_id,
            whatapp_number,
            email_id,
            website,
            logo,
            currency_id: currency_id?.country_id,
            bank_name,
            branch_name,
            bank_account_number,
            bank_ifsc_code,
            iban_number,
            vat_gst_number,
            swift_code,
            terms_condition,
            invoice_prefix,
            invoice_suffix,
            tax_type: tax_type?.value,
          });
        }
      });
    }
    submitData.companies = allCompanys;

    // console.log("submitData", submitData);
    // return false;

    const response = await postData(`user/update_profile`, submitData);

    if (!response.status) {
      if (response.message) {
        addToast(response.message, { appearance: "error", autoDismiss: true });
      }
      if (response.errors) {
        let apiErrors = response.errors;
        for (let i = 0; i < apiErrors.length; i++) {
          let errorMessage = apiErrors[i].message;
          errorMessage = errorMessage.replace(/['"]+/g, "").replace(/_/g, " ");
          errorMessage =
            errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
          addToast(errorMessage, { appearance: "error", autoDismiss: true });
        }
      }
    } else {
      getProfile();
      addToast("Profile has been updated successfully", {
        appearance: "success",
        autoDismiss: true,
      });
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);

      //navigate(-1);
    }
  };

  //Update Profile formik const
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema: ProfileSchema,
    onSubmit: (values, action) => {
      handleFormSubmit(values, action);
    },
  });

  const commaonError = (fieldName) => {
    return errors?.[fieldName] && touched?.[fieldName] ? (
      <p className="formError">{errors[fieldName]}</p>
    ) : null;
  };

  const commonPasswordError = (fieldName) => {
    return upErrors?.[fieldName] && upTouched?.[fieldName] ? (
      <p className="formError">{upErrors[fieldName]}</p>
    ) : null;
  };

  const handleFileChange = async (event) => {
    let name = event.target.name;
    let val = event.target.files[0];
    let uploadFile = { file: val };
    const url = `upload_any/do_upload`;
    const response = await doUpload(url, uploadFile);
    if (response.status && response.uploadedFile) {
      let filePath = response.uploadedFile.path;
      //setFormData({ ...formData, [name]: filePath });
      formData.company_logo = filePath;
      setFieldValue("company_logo", filePath);
    } else {
      formData.company_logo = "";
      setFieldValue("company_logo", "");
      setFieldError("company_logo", response.message);
    }
  };

  // START Manage company
  const addMoreCompany = () => {
    setCompanys([...companys, {}]);
  };
  const handleCompanyChange = async (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...companys];
    if (name == "logo") {
      let val = e.target.files[0];
      let uploadFile = { file: val };
      const url = `upload_any/do_upload`;
      const response = await doUpload(url, uploadFile);
      if (response.status && response.uploadedFile) {
        let filePath = response.uploadedFile.path;
        onchangeVal[i][name] = filePath;
      } else {
        onchangeVal[i][name] = "";
      }
    } else {
      onchangeVal[i][name] = value;
    }
    setCompanys(onchangeVal);
  };

  const handleProfileChange = async (e) => {
    const { name, value } = e.target;
    let val = e.target.files[0];
    let uploadFile = { file: val };
    const url = `upload_any/do_upload`;
    const response = await doUpload(url, uploadFile);
    if (response.status && response.uploadedFile) {
      let filePath = response.uploadedFile.path;
      setFormData((values) => ({ ...values, [name]: filePath }));
    } else {
      setFormData((values) => ({ ...values, [name]: "" }));
    }
  };

  const handleCompanyEditorChange = async (value, i) => {
    const onchangeVal = [...companys];
    onchangeVal[i]["terms_condition"] = value;
    setCompanys(onchangeVal);
  };
  // const handleCompanyDelete = (removeIndex) => {
  //   const datas = [...companys];
  //   let newDatas = datas.filter((data, i) => {
  //     return i != removeIndex;
  //   });
  //   setCompanys(newDatas);
  // };

  const handleCompanyDelete = (i) => {
    const deleteVal = [...companys];
    deleteVal.splice(i, 1);
    setCompanys(deleteVal);
  };

  // END Manage company

  const taxTypeOptions = [
    { label: "GST", value: "GST" },
    { label: "VAT", value: "VAT" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Formik
          initialValues={formData}
          enableReinitialze={true}
          onSubmit={handleSubmit}
        >
          <Form>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <CardTopBoxBack title="Profile" />
                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="first_name"
                                  type="text"
                                  label="First Name"
                                  fullWidth
                                  value={values.first_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("first_name")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="last_name"
                                  type="text"
                                  label="Last Name"
                                  fullWidth
                                  value={values.last_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("last_name")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="mobile_number"
                                  type="tel"
                                  label="Mobile Number"
                                  fullWidth
                                  value={values.mobile_number}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("mobile_number")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="email_id"
                                  readonly="true"
                                  type="email"
                                  disabled
                                  label="Email ID"
                                  fullWidth
                                  value={values.email_id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  margin: "0px 2px",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  component="label"
                                  fullWidth
                                  color="success"
                                >
                                  Profile Pic
                                  <input
                                    type="file"
                                    name="profile_pic"
                                    accept="image/png, image/jpeg"
                                    hidden
                                    onChange={(e) => handleProfileChange(e)}
                                  />
                                </Button>
                                {values.profile_pic && (
                                  <>
                                    <Link
                                      to={`${process.env.REACT_APP_URI_PATH}${values.profile_pic}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="mdsViewFile"
                                    >
                                      <MDButton
                                        variant="gradient"
                                        color="primary"
                                        size="small"
                                        type="button"
                                      >
                                        View Pic
                                      </MDButton>
                                    </Link>
                                  </>
                                )}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>

                {/* Start company Management */}
                {formData.user_id === 1 && (
                  <>
                    <Card className="mt4rem">
                      <MDBox
                        mx={2}
                        mt={-5}
                        py={1}
                        borderRadius="lg"
                        px={2}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        variant="gradient"
                        bgColor="info"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          Company Management
                        </MDTypography>
                        <MDButton
                          variant="outlined"
                          onClick={() => addMoreCompany()}
                          color="white"
                          size="small"
                        >
                          Add company
                        </MDButton>
                      </MDBox>

                      <MDBox
                        variant="contained"
                        bgColor="white"
                        color="black"
                        borderRadius="md"
                        shadow="md"
                      >
                        <DialogContent>
                          <Box sx={{ flexGrow: 1 }}>
                            <MDBox pt={1} pb={1}>
                              <Grid container spacing={2}>
                                {companys.map((val, i) => (
                                  <Grid
                                    item
                                    className="borderBottom"
                                    style={{
                                      borderBottom: "1px solid #33333338",
                                      marginBottom: "10px",
                                    }}
                                    container
                                    xs={12}
                                    md={12}
                                    key={i}
                                    spacing={2}
                                  >
                                    <Grid item xs={12} md={3}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          name="name"
                                          type="text"
                                          required
                                          label="Company Name"
                                          fullWidth
                                          value={val.name}
                                          onChange={(e) =>
                                            handleCompanyChange(e, i)
                                          }
                                        />
                                      </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          name="address"
                                          type="text"
                                          label="Company Address"
                                          fullWidth
                                          value={val.address}
                                          onChange={(e) =>
                                            handleCompanyChange(e, i)
                                          }
                                        />
                                      </MDBox>
                                    </Grid>
                                    <Grid
                                      item
                                      container
                                      xs={12}
                                      md={3}
                                      spacing={0}
                                    >
                                      <Grid item xs={6} md={6}>
                                        <MDBox
                                          mb={2}
                                          className="mdsAutocompleteGrid"
                                        >
                                          <Autocomplete
                                            value={val.landline_code_id}
                                            size="small"
                                            name="landline_code_id"
                                            onChange={(e, newValue) => {
                                              const landlineCodeId = [
                                                ...companys,
                                              ];
                                              if (newValue) {
                                                landlineCodeId[i][
                                                  "landline_code_id"
                                                ] = newValue;
                                                setCompanys(landlineCodeId);
                                              } else {
                                                landlineCodeId[i][
                                                  "landline_code_id"
                                                ] = null;
                                                setCompanys(landlineCodeId);
                                              }
                                            }}
                                            options={countryList}
                                            getOptionLabel={(countryList) => {
                                              if (
                                                countryList.dial_code &&
                                                countryList.name
                                              ) {
                                                return (
                                                  countryList.dial_code +
                                                  " " +
                                                  countryList.name
                                                );
                                              }
                                              return "";
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Country Code"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            )}
                                          />
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={6} md={6}>
                                        <MDBox mb={2}>
                                          <MDInput
                                            name="landline_number"
                                            type="text"
                                            label="Landline Number"
                                            fullWidth
                                            value={val.landline_number}
                                            onChange={(e) =>
                                              handleCompanyChange(e, i)
                                            }
                                          />
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      item
                                      container
                                      xs={12}
                                      md={3}
                                      spacing={0}
                                    >
                                      <Grid item xs={6} md={6}>
                                        <MDBox
                                          mb={2}
                                          className="mdsAutocompleteGrid"
                                        >
                                          <Autocomplete
                                            value={val.mobile_code_id}
                                            size="small"
                                            name="mobile_code_id"
                                            onChange={(e, newValue) => {
                                              const mobileCodeId = [
                                                ...companys,
                                              ];
                                              if (newValue) {
                                                mobileCodeId[i][
                                                  "mobile_code_id"
                                                ] = newValue;
                                                setCompanys(mobileCodeId);
                                              } else {
                                                mobileCodeId[i][
                                                  "mobile_code_id"
                                                ] = null;
                                                setCompanys(mobileCodeId);
                                              }
                                            }}
                                            options={countryList}
                                            getOptionLabel={(countryList) => {
                                              if (
                                                countryList.dial_code &&
                                                countryList.name
                                              ) {
                                                return (
                                                  countryList.dial_code +
                                                  " " +
                                                  countryList.name
                                                );
                                              }
                                              return "";
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Country Code"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            )}
                                          />
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={6} md={6}>
                                        <MDBox mb={2}>
                                          <MDInput
                                            name="mobile_number"
                                            type="text"
                                            label="Mobile Number"
                                            fullWidth
                                            value={val.mobile_number}
                                            onChange={(e) =>
                                              handleCompanyChange(e, i)
                                            }
                                          />
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      item
                                      container
                                      xs={12}
                                      md={3}
                                      spacing={0}
                                    >
                                      <Grid item xs={6} md={6}>
                                        <MDBox
                                          mb={2}
                                          className="mdsAutocompleteGrid"
                                        >
                                          <Autocomplete
                                            value={val.whatapp_code_id}
                                            size="small"
                                            name="whatapp_code_id"
                                            onChange={(e, newValue) => {
                                              const whatappCodeId = [
                                                ...companys,
                                              ];
                                              if (newValue) {
                                                whatappCodeId[i][
                                                  "whatapp_code_id"
                                                ] = newValue;
                                                setCompanys(whatappCodeId);
                                              } else {
                                                whatappCodeId[i][
                                                  "whatapp_code_id"
                                                ] = null;
                                                setCompanys(whatappCodeId);
                                              }
                                            }}
                                            options={countryList}
                                            getOptionLabel={(countryList) => {
                                              if (
                                                countryList.dial_code &&
                                                countryList.name
                                              ) {
                                                return (
                                                  countryList.dial_code +
                                                  " " +
                                                  countryList.name
                                                );
                                              }
                                              return "";
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Country Code"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            )}
                                          />
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={6} md={6}>
                                        <MDBox mb={2}>
                                          <MDInput
                                            name="whatapp_number"
                                            type="text"
                                            label="Whatapp Number"
                                            fullWidth
                                            value={val.whatapp_number}
                                            onChange={(e) =>
                                              handleCompanyChange(e, i)
                                            }
                                          />
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          name="email_id"
                                          type="text"
                                          label="Email Id"
                                          fullWidth
                                          value={val.email_id}
                                          onChange={(e) =>
                                            handleCompanyChange(e, i)
                                          }
                                        />
                                      </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          name="website"
                                          type="text"
                                          label="Website"
                                          fullWidth
                                          value={val.website}
                                          onChange={(e) =>
                                            handleCompanyChange(e, i)
                                          }
                                        />
                                      </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <MDBox
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          margin: "0px 2px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          component="label"
                                          fullWidth
                                          color="success"
                                        >
                                          Company Logo
                                          <input
                                            type="file"
                                            name="logo"
                                            accept="image/png, image/jpeg, application/pdf"
                                            hidden
                                            onChange={(e) =>
                                              handleCompanyChange(e, i)
                                            }
                                          />
                                        </Button>
                                        {val.logo && (
                                          <>
                                            <Link
                                              to={`${process.env.REACT_APP_URI_PATH}${val.logo}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="mdsViewFile"
                                            >
                                              <MDButton
                                                variant="gradient"
                                                color="primary"
                                                size="small"
                                                type="button"
                                              >
                                                View Logo
                                              </MDButton>
                                            </Link>
                                          </>
                                        )}
                                      </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <MDBox
                                        mb={2}
                                        className="mdsAutocompleteGrid"
                                      >
                                        <Autocomplete
                                          value={val.currency_id}
                                          size="small"
                                          name="currency_id"
                                          onChange={(e, newValue) => {
                                            const currencyId = [...companys];
                                            if (newValue) {
                                              currencyId[i]["currency_id"] =
                                                newValue;
                                              setCompanys(currencyId);
                                            } else {
                                              currencyId[i]["currency_id"] =
                                                null;
                                              setCompanys(currencyId);
                                            }
                                          }}
                                          options={currencyList}
                                          getOptionLabel={(currencyList) => {
                                            if (
                                              currencyList.dial_code &&
                                              currencyList.name
                                            ) {
                                              return currencyList.currency_code;
                                            }
                                            return "";
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Company Currency"
                                              InputLabelProps={{ shrink: true }}
                                            />
                                          )}
                                        />
                                      </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          name="bank_name"
                                          type="text"
                                          label="Bank Name"
                                          fullWidth
                                          value={val.bank_name}
                                          onChange={(e) =>
                                            handleCompanyChange(e, i)
                                          }
                                        />
                                      </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          name="branch_name"
                                          type="text"
                                          label="Branch Name"
                                          fullWidth
                                          value={val.branch_name}
                                          onChange={(e) =>
                                            handleCompanyChange(e, i)
                                          }
                                        />
                                      </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          name="bank_account_number"
                                          type="text"
                                          label="Bank Account Number"
                                          fullWidth
                                          value={val.bank_account_number}
                                          onChange={(e) =>
                                            handleCompanyChange(e, i)
                                          }
                                        />
                                      </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          name="bank_ifsc_code"
                                          type="text"
                                          label="Bank IFSC Code"
                                          fullWidth
                                          value={val.bank_ifsc_code}
                                          onChange={(e) =>
                                            handleCompanyChange(e, i)
                                          }
                                        />
                                      </MDBox>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          name="iban_number"
                                          type="text"
                                          label="IBAN Number"
                                          fullWidth
                                          value={val.iban_number}
                                          onChange={(e) =>
                                            handleCompanyChange(e, i)
                                          }
                                        />
                                      </MDBox>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                      <MDBox
                                        mb={2}
                                        className="mdsAutocompleteGrid"
                                      >
                                        <Autocomplete
                                          value={val.tax_type}
                                          size="small"
                                          name="tax_type"
                                          required
                                          onChange={(e, newValue) => {
                                            const taxTypeId = [...companys];
                                            if (newValue) {
                                              taxTypeId[i]["tax_type"] =
                                                newValue;
                                              setCompanys(taxTypeId);
                                            } else {
                                              taxTypeId[i]["tax_type"] = null;
                                              setCompanys(taxTypeId);
                                            }
                                          }}
                                          options={taxTypeOptions}
                                          getOptionLabel={(taxTypeOptions) =>
                                            taxTypeOptions.label || ""
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Tax Type *"
                                              InputLabelProps={{ shrink: true }}
                                            />
                                          )}
                                        />
                                      </MDBox>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          name="vat_gst_number"
                                          type="text"
                                          label="VAT/GST Number"
                                          fullWidth
                                          value={val.vat_gst_number}
                                          onChange={(e) =>
                                            handleCompanyChange(e, i)
                                          }
                                        />
                                      </MDBox>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          name="swift_code"
                                          type="text"
                                          label="SWIFT / MICR Code"
                                          fullWidth
                                          value={val.swift_code}
                                          onChange={(e) =>
                                            handleCompanyChange(e, i)
                                          }
                                        />
                                      </MDBox>
                                    </Grid>

                                    <Grid
                                      item
                                      container
                                      xs={12}
                                      md={3}
                                      spacing={0}
                                    >
                                      <Grid item xs={6} md={6}>
                                        <MDBox mb={2}>
                                          <MDInput
                                            name="invoice_prefix"
                                            type="text"
                                            required
                                            label="Invoice Prefix"
                                            fullWidth
                                            value={val.invoice_prefix}
                                            onChange={(e) =>
                                              handleCompanyChange(e, i)
                                            }
                                          />
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={6} md={6}>
                                        <MDBox mb={2}>
                                          <MDInput
                                            name="invoice_suffix"
                                            type="number"
                                            required
                                            label="Invoice Suffix"
                                            fullWidth
                                            value={val.invoice_suffix}
                                            onChange={(e) =>
                                              handleCompanyChange(e, i)
                                            }
                                          />
                                        </MDBox>
                                      </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                      <MDBox mb={2}>
                                        {/* <label>Terms and Condition</label> */}
                                        <SunEditor
                                          setContents={val.terms_condition}
                                          onChange={(e) =>
                                            handleCompanyEditorChange(e, i)
                                          }
                                          placeholder="Please enter terms and condition..."
                                          name="terms_condition"
                                          setOptions={{
                                            height: 250,
                                            buttonList: [
                                              [
                                                "undo",
                                                "redo",
                                                "fontSize",
                                                "formatBlock",
                                                "align",
                                              ],
                                              [
                                                "bold",
                                                "underline",
                                                "italic",
                                                "strike",
                                                "subscript",
                                                "superscript",
                                                "removeFormat",
                                              ],
                                              [
                                                "fontColor",
                                                "hiliteColor",
                                                "outdent",
                                                "indent",
                                                "align",
                                                "horizontalRule",
                                                "list",
                                                "table",
                                              ],
                                              ["fullScreen", "preview"],
                                            ],
                                          }}
                                        />
                                      </MDBox>
                                    </Grid>

                                    <Grid item xs={12} md={2}>
                                      <IconButton className="mdsRemoveBtn">
                                        <DeleteForeverIcon
                                          variant="gradient"
                                          color="error"
                                          type="button"
                                          fontSize="large"
                                          onClick={() => handleCompanyDelete(i)}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                ))}
                              </Grid>
                            </MDBox>
                          </Box>
                        </DialogContent>
                      </MDBox>
                    </Card>
                  </>
                )}
              </Grid>
            </Grid>
            <MDBox mt={2} display="flex" alignItems="center">
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                type="submit"
                className="saveButton"
              >
                Save
              </MDButton>
            </MDBox>
          </Form>
        </Formik>

        <Formik
          initialValues={updatePasswordFormData}
          enableReinitialze={true}
          onSubmit={handleUpdatePasswordSubmit}
        >
          <Form>
            <Grid container spacing={6} style={{ marginTop: "1rem" }}>
              <Grid item xs={12}>
                <Card>
                  <CardTopBoxBack
                    title="Change Password"
                    showBackButton={false}
                  />
                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="old_password"
                                  type="password"
                                  label="Current Password"
                                  fullWidth
                                  value={upValues.old_password}
                                  onChange={handleUpChange}
                                  onBlur={handleUpBlur}
                                />
                                {commonPasswordError("old_password")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="password"
                                  type="password"
                                  label="New Password"
                                  fullWidth
                                  value={upValues.password}
                                  onChange={handleUpChange}
                                  onBlur={handleUpBlur}
                                />
                                {commonPasswordError("password")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="confirm_password"
                                  type="password"
                                  label="Confirm New Password"
                                  fullWidth
                                  value={upValues.confirm_password}
                                  onChange={handleUpChange}
                                  onBlur={handleUpBlur}
                                />
                                {commonPasswordError("confirm_password")}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
            <MDBox mt={2} display="flex" alignItems="center">
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                type="submit"
                className="saveButton"
              >
                Save
              </MDButton>
            </MDBox>
          </Form>
        </Formik>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Profile;
