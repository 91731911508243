import * as Yup from "yup";

export const interviewSchema = Yup.object({
  //   application_name: Yup.string()
  //     .min(2, "Please enter minimum two characters")
  //     .required("Please enter applicant name")
  //     .max(250, "Max 250 characters"),
  area: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please select area")
    .max(120),
  interview_date_time: Yup.date().required(
    "Please provide interview date time"
  ),
  company_id: Yup.mixed().required("Please select company"),
  position_id: Yup.mixed().required("Please select position"),
  //outcome: Yup.string().min(2, "Please enter minimum two characters").required("Please enter outcome").max(250, "Max 250 characters"),
  status_id: Yup.mixed().required("Please select status"),
  remarks: Yup.string()
    .min(2, "Please enter minimum 2 characters")
    .required("Please enter remarks")
    .max(250, "Max 250 characters allowed"),
});
