import * as Yup from "yup";

export const createMeetingSchema = Yup.object({
  user_id: Yup.mixed().required("Please select organise user"),
  reason_id: Yup.mixed().required("Please select agenda"),
  vendor_type: Yup.mixed().required("Please select vendor type"),
  meeting_with: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter meeting with")
    .max(250),
  meeting_mode: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter meeting mode")
    .max(250),
  meeting_date: Yup.date().required("Please provide meeting date"),
  start_time: Yup.mixed().required("Please select start time"),
  end_time: Yup.mixed().required("Please select end time"),
  status_id: Yup.mixed().required("Please select status"),
  remarks: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter remarks")
    .max(250),
});
