import * as Yup from "yup";

export const creditNoteSchema = Yup.object({
  credit_note_number: Yup.string()
    .required("Please enter credit note number")
    .max(120, "Max 120 characters allowed"),
  credit_note_date: Yup.date().required("Please provide credit note date"),
  credit_note_remarks: Yup.string()
    .required("Please enter credit note remarks")
    .max(250, "Max 250 characters allowed"),

  credit_note_amount: Yup.number()
    .typeError("Please enter credit note amount")
    .required("Please enter credit note amount"),
});
