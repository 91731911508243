// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { fetchData } from "utils/clientFunctions";
import { useEffect, useRef, useState } from "react";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import MDAvatar from "components/MDAvatar";
import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import { useAuth } from "utils/auth";
import pxToRem from "assets/theme/functions/pxToRem";
import { dateFormat } from "utils/clientFunctions";
import { CardTopBoxAdd } from "layouts/commonComponents/cardTopBoxAdd";
import AccountRes from "../components/accountRes";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import { firstLetterCapital } from "utils/clientFunctions";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CSVDownload, CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { dateTimeStamp } from "utils/clientFunctions";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function AccountsList() {
  const csvLink = useRef();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageResize, setPageResize] = useState(
    window.innerWidth < 992 ? true : false
  );
  const navigate = useNavigate();

  //Start : For permission
  const dispatch = useDispatch();
  var permissions = useSelector(selectPermissions);
  if (
    !(
      permissions?.user_id === 1 || permissions?.permission?.includes("accView")
    )
  ) {
    navigate("/dashboard");
  }
  let showAddButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("accCreate")
      ? true
      : false;
  let showEditButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("accEdit")
      ? true
      : false;
  let showDeleteButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("accDelete")
      ? true
      : false;
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const [List, setList] = useState([]);
  const [raData, setRaData] = useState({});

  const [paymentTypes, setPaymentTypes] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [applicantList, setApplicantList] = useState([]);
  const [raList, setRaList] = useState([]);
  const [usersList, setUserList] = useState([]);
  const [payments, setPayments] = useState([]);

  const [accountInfo, setAccountInfo] = useState([]);

  const { logout } = useAuth();

  const initialLoadData = async () => {
    let queryUrl = getQueryParams();
    const url = `account/list${queryUrl}`;
    const data = await fetchData(url);
    if (
      data?.name === "JsonWebTokenError" ||
      data?.name === "TokenExpiredError" ||
      data?.message === "You are not authorized"
    ) {
      logout();
    }
    if (data && data.data) {
      setPageCount(Math.ceil(data.count / 10));
      setList(data.data || []);
    }
  };

  //Search & Filter initial data
  const [searchFormData, setSearchFormData] = useState({
    debit_credit_type: "",
    vendor_type: "",
    vendor_id: "",
    payment_status: "",
  });

  useEffect(() => {
    initialLoadData();
  }, [
    pageIndex,
    searchFormData.debit_credit_type,
    searchFormData.vendor_type,
    searchFormData.vendor_id,
    searchFormData.payment_status,
  ]);

  useEffect(() => {
    const companyUrl = `company/list?page=1`;
    getFetchData(companyUrl).then((res) => {
      setCompanyList(res.data);
    });

    const applicantUrl = `applicant/list?page=1`;
    getFetchData(applicantUrl).then((res) => {
      setApplicantList(res.data);
    });

    const raUrl = `recruiting_agency/list?page=1`;
    getFetchData(raUrl).then((res) => {
      setRaList(res.data);
    });

    const userUrl = `user/list?page=1`;
    getFetchData(userUrl).then((res) => {
      setUserList(res.data);
    });
  }, []);

  const doPaginationChange = (event, value) => {
    setPageIndex(value);
  };

  //Dialog popup
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = async (raInfo) => {
    await getAccountInfo(raInfo.account_id); // Get account info
    setRaData(raInfo);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setRaData({});
    setOpenDialog(false);
  };
  const searchSpot = (value, key) => {
    liveSearchData({
      countryId: null,
      value,
      key,
      setRaList,
      setCompanyList,
      setApplicantList,
      setUserList,
    });
  };
  window.addEventListener("resize", () => {
    if (window.innerWidth < 992) {
      setPageResize(true);
    } else {
      setPageResize(false);
    }
  });

  //Get the payments list
  const getAccountInfo = async (accountId) => {
    const res = await getFetchData(`account/${accountId}`);
    setAccountInfo(res);
  };

  const ViewMoreRa = ({ openDialog, raData, handleCloseDialog }) => {
    const {
      account_id,
      debit_credit_type,
      acc_amount,
      acc_date,
      vendor_type,
      vendor_name,
      party_name,
      party_type,
      payment_status,
      remarks,
      payment_value,
      country_currency_code,
      country_currency_name,
    } = raData;
    console.log("ra", raData);
    let paymentList = accountInfo?.account?.payment_list;

    return (
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        fullScreen={window.innerWidth < 768 ? true : false}
      >
        <DialogTitle sx={{ m: 0 }} id="customized-dialog-title">
          {"Account Details"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container px={2} spacing={1}>
            <ViewTypo
              title={"Debit/Credit"}
              res={firstLetterCapital(debit_credit_type)}
            />
            <ViewTypo title={"Vendor Name"} res={vendor_name} />
            <ViewTypo
              title={"Vendor Type"}
              res={
                vendor_type === "ra"
                  ? "Recruiting agency"
                  : vendor_type === "company"
                  ? "Company"
                  : vendor_type === "applicant"
                  ? "Applicant"
                  : ""
              }
            />
            <ViewTypo title={"Party Name"} res={party_name} />
            <ViewTypo
              title={"Party Type"}
              res={
                party_type === "ra"
                  ? "Recruiting agency"
                  : party_type === "company"
                  ? "Company"
                  : party_type === "applicant"
                  ? "Applicant"
                  : party_type === "user"
                  ? "Staff/Partner"
                  : ""
              }
            />

            <ViewTypo title={"Payment Type"} res={payment_value} />

            <ViewTypo
              title={"Amount"}
              res={country_currency_code + " " + acc_amount * 1}
            />
            <ViewTypo title={"Status"} res={payment_status} />
            <ViewTypo
              title={"  Date"}
              res={acc_date ? dateFormat(acc_date) : ""}
            />
            <ViewTypo title={"Remarks"} res={remarks} />

            <Grid item xs={12} md={12} lg={12}>
              <MDTypography variant="h6" fontWeight="medium">
                {paymentList?.length ? (
                  <MDBox
                    py={1}
                    px={1}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      List of Payments
                    </MDTypography>
                  </MDBox>
                ) : (
                  ""
                )}
              </MDTypography>
            </Grid>
            {paymentList?.map(({ pay_amount, pay_date, remark }, i) => {
              return (
                <>
                  <Grid item xs={12} md={12} lg={12}>
                    <MDTypography variant="h6" fontWeight="medium">
                      {i + 1}.
                    </MDTypography>
                  </Grid>
                  <ViewTypo
                    title={"Amount"}
                    res={country_currency_code + " " + pay_amount * 1}
                  />
                  <ViewTypo title={"Date"} res={dateFormat(pay_date)} />
                  <ViewTypo title={"Remarks"} res={remark} />
                </>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    );
  };

  const getFetchData = async (settingUrl) => {
    return await fetchData(settingUrl);
  };

  const getCountryinfo = (countryId = false) => {
    const raUrl = `country/${countryId}`;
    getFetchData(raUrl).then((res) => {
      return res.data;
    });
  };

  const HeadTypo = ({ title, lg }) => {
    return (
      <Grid item xs={4} md={4} lg={lg}>
        <MDTypography fontSize={pxToRem(14)} fontWeight="medium">
          {title}
        </MDTypography>
      </Grid>
    );
  };

  const BodyTypo = ({ title, lg }) => {
    return (
      <Grid item xs={4} md={4} lg={lg}>
        <MDTypography fontSize={pxToRem(14)} fontWeight="regular">
          {title}
        </MDTypography>
      </Grid>
    );
  };

  const ViewTypo = ({ title, res }) => {
    return (
      <>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="medium">
            {title}
          </MDTypography>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="regular">
            {res}
          </MDTypography>
        </Grid>
      </>
    );
  };

  //START : Search and Filter form data
  //Handle onchange search and filter
  const onChangeSearch = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setSearchFormData({ ...searchFormData, [name]: val });
  };
  const [csvData, setCsvData] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);

  let csvHeaders = [
    { label: "Debit/Credit", key: "debit_credit_type" },
    { label: "Vendor Type", key: "vendor_type" },
    { label: "Vendor Name", key: "vendor_name" },
    { label: "Party Type", key: "party_type" },
    { label: "Party Name", key: "party_name" },
    { label: "Payment Type", key: "payment_value" },
    { label: "Amount", key: "amount" },
    { label: "Payment Status", key: "payment_status" },
    { label: "Date", key: "acc_date" },
  ];
  //Prepare the csv data for export
  const exportCsv = async () => {
    let queryUrl = getQueryParams();
    queryUrl = queryUrl + `&download=1`;
    getFetchData(`account/list${queryUrl}`).then(async (res) => {
      if (res?.data) {
        let exportData = [];
        await Promise.all(
          res?.data.map((report) => {
            report.debit_credit_type = firstLetterCapital(
              report?.debit_credit_type
            );

            report.vendor_type =
              report?.vendor_type == "ra"
                ? "Recruiting Agency"
                : firstLetterCapital(report?.vendor_type);

            report.party_type =
              report?.party_type == "ra"
                ? "Recruiting Agency"
                : firstLetterCapital(report?.party_type);

            report.amount =
              report?.country_currency_code + " " + report?.acc_amount * 1;

            report.acc_date = report?.acc_date
              ? dateFormat(report?.acc_date)
              : "-";
            exportData.push(report);
          })
        );
        setCsvData(exportData); //Set the csv data
        setDownloadCsv(true); // Show the CSVDwonload link
        setTimeout(() => {
          csvLink?.current?.link?.click();
          setDownloadCsv(false); // Hide the CSVDwonload link
        }, 100);
      }
    });
  };
  //Prepare the query string for search and filter
  const getQueryParams = () => {
    let queryUrl = `?page=${pageIndex}`;

    queryUrl = searchFormData?.debit_credit_type?.value
      ? queryUrl +
        "&debit_credit_type=" +
        searchFormData?.debit_credit_type?.value
      : queryUrl;

    let vendorType = searchFormData?.vendor_type?.value;

    let vendorTypeId =
      vendorType === "company"
        ? searchFormData?.vendor_id?.company_id
        : vendorType === "ra"
        ? searchFormData?.vendor_id?.ra_id
        : vendorType === "applicant"
        ? searchFormData?.vendor_id?.applicant_id
        : "";

    queryUrl = vendorType ? queryUrl + "&vendor_type=" + vendorType : queryUrl;

    queryUrl = vendorTypeId
      ? queryUrl + "&vendor_id=" + vendorTypeId
      : queryUrl;

    queryUrl = searchFormData?.payment_status?.value
      ? queryUrl + "&payment_status=" + searchFormData?.payment_status?.value
      : queryUrl;

    return queryUrl;
  };
  //END : Search and Filter form data

  const vendorLeadOptions = [
    { label: "Recruiting agency", value: "ra" },
    { label: "Company", value: "company" },
    { label: "Applicant", value: "applicant" },
  ];

  const paymentOptions = [
    { label: "Not paid", value: "Not paid" },
    { label: "Partially paid", value: "Partially paid" },
    { label: "Paid", value: "Paid" },
  ];

  const creditDebitOptions = [
    { label: "Credit", value: "credit" },
    { label: "Debit", value: "debit" },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <CardTopBoxAdd
                title={"Accounts"}
                link={"/accountdata"}
                showAddButton={showAddButton}
              />
              <MDBox>
                <Grid container p={2} spacing={2}>
                  {/* START : Search and filter block */}
                  <Grid
                    container
                    className="searchFilterBlock"
                    p={2}
                    spacing={2}
                    mt={1}
                  >
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.debit_credit_type}
                          size="small"
                          name="debit_credit_type"
                          options={creditDebitOptions}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["debit_credit_type"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["debit_credit_type"]: "",
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Debit/Credit"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.vendor_type}
                          size="small"
                          name="vendor_type"
                          options={vendorLeadOptions}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["vendor_type"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["vendor_type"]: "",
                              });
                            }
                          }}
                          required
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Vendor Type"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.vendor_id}
                          size="small"
                          name="vendor_id"
                          options={
                            searchFormData.vendor_type?.value === "ra"
                              ? raList
                              : searchFormData.vendor_type?.value ===
                                "applicant"
                              ? applicantList
                              : searchFormData.vendor_type?.value === "company"
                              ? companyList
                              : []
                          }
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["vendor_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["vendor_id"]: "",
                              });
                            }
                          }}
                          getOptionLabel={(paymentOptions) =>
                            searchFormData.vendor_type?.value === "ra"
                              ? paymentOptions?.ra_name || ""
                              : searchFormData.vendor_type?.value ===
                                "applicant"
                              ? paymentOptions?.first_name
                                ? paymentOptions?.first_name +
                                  " " +
                                  paymentOptions.last_name
                                : ""
                              : searchFormData.vendor_type?.value === "company"
                              ? paymentOptions?.name || ""
                              : ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Vendor Name"
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) =>
                                searchSpot(
                                  searchFormData.vendor_type?.value,
                                  e.target.value
                                )
                              }
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.payment_status}
                          size="small"
                          name="payment_status"
                          options={paymentOptions}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["payment_status"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["payment_status"]: "",
                              });
                            }
                          }}
                          getOptionLabel={(paymentOptions) =>
                            paymentOptions.label || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Payment Status"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox display="flex" alignItems="center">
                        {/* {downloadCsv && (
                          <>
                            <CSVDownload
                              data={csvData}
                              filename={"my-3534564-file.csv"}
                              headers={csvHeaders}
                              target="_self"
                            />
                          </>
                        )} */}
                        <CSVLink
                          className=" d-none "
                          data={csvData}
                          filename={`Accounts ${dateTimeStamp()}`}
                          headers={csvHeaders}
                          ref={csvLink}
                          target="_self"
                        />
                        <MDButton
                          variant="gradient"
                          color="primary"
                          size="small"
                          type="button"
                          className="searchButton"
                          onClick={exportCsv}
                        >
                          <DownloadIcon /> Export CSV
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                  {/* END : Search and filter block */}

                  {!pageResize && (
                    <Grid item xs={12} md={12} lg={12}>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <HeadTypo title="Debit/Credit" lg={1} />
                        <HeadTypo title="Vendor" lg={2} />
                        <HeadTypo title="Payment Type" lg={2} />
                        <HeadTypo title="Amount" lg={1} />
                        <HeadTypo title="Party" lg={2} />
                        <HeadTypo title="Contact Number" lg={2} />
                        <HeadTypo title="Status" lg={1} />
                        <HeadTypo title="Action" lg={1} />
                      </MDBox>
                    </Grid>
                  )}
                  {List.map((raItem, index) => {
                    let {
                      account_id,
                      debit_credit_type,
                      vendor_name,
                      party_name,
                      party_contact_country_code,
                      party_contact_number,
                      payment_status,
                      remarks,
                      payment_value,
                      acc_amount,
                      country_currency_code,
                    } = raItem;

                    let countryData;
                    // if (party_contact_country_code) {
                    //   countryData = getCountryinfo(party_contact_country_code);
                    // }
                    // console.log(
                    //   `party_contact_country_code : ${party_contact_country_code} && countryData : ${countryData}`
                    // );
                    party_contact_country_code = party_contact_country_code
                      ? party_contact_country_code
                      : "";
                    let contactNumber =
                      party_contact_country_code + " " + party_contact_number;

                    return (
                      <>
                        {!pageResize ? (
                          <Grid item key={account_id} xs={12} md={12} lg={12}>
                            <MDBox
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              p={1}
                              sx={{
                                border: ({
                                  borders: { borderWidth, borderColor },
                                }) => `${borderWidth[1]} solid ${borderColor}`,
                              }}
                            >
                              <BodyTypo
                                title={firstLetterCapital(debit_credit_type)}
                                lg={1}
                              />
                              <BodyTypo title={vendor_name} lg={2} />
                              <BodyTypo title={payment_value} lg={2} />
                              <BodyTypo
                                title={
                                  country_currency_code + " " + acc_amount * 1
                                }
                                lg={1}
                              />
                              <BodyTypo title={party_name} lg={2} />
                              <BodyTypo title={party_contact_number} lg={2} />
                              <BodyTypo title={payment_status} lg={1} />
                              <Grid item xs={4} md={4} lg={1}>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  color="inherit"
                                  onClick={() => handleOpenDialog(raItem)}
                                >
                                  <RemoveRedEye />
                                </IconButton>
                                {showEditButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    onClick={() =>
                                      navigate(`/accountdata/${account_id}`)
                                    }
                                  >
                                    <Edit />
                                  </IconButton>
                                )}
                                {showDeleteButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                  >
                                    <Delete />
                                  </IconButton>
                                )}
                              </Grid>
                            </MDBox>
                          </Grid>
                        ) : (
                          <AccountRes
                            key={account_id}
                            raItem={raItem}
                            index={index + 1}
                            handleOpenDialog={handleOpenDialog}
                            showEditButton={showEditButton}
                            showDeleteButton={showDeleteButton}
                          />
                        )}
                      </>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack spacing={2} mb={2} ml={2}>
                    <Pagination
                      count={pageCount}
                      page={pageIndex}
                      onChange={doPaginationChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ViewMoreRa
        openDialog={openDialog}
        raData={raData}
        handleCloseDialog={handleCloseDialog}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default AccountsList;
