import * as Yup from "yup";

export const taxSchema = Yup.object({
  name: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter tax name")
    .max(50, "Max 50 characters"),
  percentage: Yup.number()
    .typeError("Enter valid percentage")
    .required("Please provide percentage")
    .min(1)
    .max(100, "Maximum 100 allowed"),
});
