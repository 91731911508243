import { Link, Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "./auth";
import { ToastProvider } from "react-toast-notifications";
export const ProtectedLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();
  if (!user) {
    return <Navigate to="/authentication/sign-in" />;
  }
  return (
    <>
      <ToastProvider placement="bottom-right">{outlet}</ToastProvider>
    </>
  );
};
