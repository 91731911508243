// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { Grid, IconButton } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import { useNavigate } from "react-router-dom";
import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import { dateFormat } from "utils/clientFunctions";

function DealRes({
  dealItem,
  handleOpenDialog,
  index,
  showEditButton,
  showDeleteButton,
}) {
  let {
    deal_id,
    receiving_country_info,
    company_info,
    supply_country_info,
    recruiting_agency_info,
    status_info,
  } = dealItem;
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const BodyTypo = ({ title }) => {
    return (
      <MDTypography variant="caption" fontWeight="bold">
        {title}
      </MDTypography>
    );
  };
  const BodyCSSTypo = ({ title }) => {
    return (
      <MDBox mb={1} lineHeight={0}>
        <MDTypography variant="caption" fontWeight="bold">
          {title}
        </MDTypography>
      </MDBox>
    );
  };

  const getCountryFlag = (countryInfo) => {
    return (
      <>
        <MDTypography variant="h6" fontWeight="regular">
          <MDAvatar
            src={`https://flagcdn.com/w40/${countryInfo?.code.toLowerCase()}.png`}
            name={countryInfo?.name}
            size="md"
            style={{ display: "inline-block", overflow: "visible" }}
            sx={{ width: 20, height: 20 }}
          />{" "}
          {countryInfo?.name}
        </MDTypography>
      </>
    );
  };
  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <MDBox
          borderRadius="lg"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={1}
          sx={{
            border: ({ borders: { borderWidth, borderColor } }) =>
              `${borderWidth[1]} solid ${borderColor}`,
          }}
        >
          <MDBox width="100%" display="flex" flexDirection="column">
            <MDBox
              mb={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <BodyTypo title={index} />
              <BodyTypo title={status_info?.setting_value} />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "center", sm: "center" }}
              flexDirection={{ xs: "row", sm: "row" }}
              // mb={2}
            >
              <MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="bold">
                    Receiving Country :{" "}
                    <MDAvatar
                      src={`https://flagcdn.com/w40/${receiving_country_info?.code.toLowerCase()}.png`}
                      name={receiving_country_info?.name}
                      size="md"
                      style={{
                        display: "inline-block",
                        overflow: "visible",
                      }}
                      sx={{ width: 20, height: 20 }}
                    />{" "}
                    {receiving_country_info?.name + " "}
                  </MDTypography>
                </MDBox>

                <BodyCSSTypo
                  title={`Company :  ${
                    company_info?.name + "-" + company_info?.company_code
                  }`}
                />
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="bold">
                    Supply Country :{" "}
                    <MDAvatar
                      src={`https://flagcdn.com/w40/${supply_country_info?.code.toLowerCase()}.png`}
                      name={supply_country_info?.name}
                      size="md"
                      style={{
                        display: "inline-block",
                        overflow: "visible",
                      }}
                      sx={{ width: 20, height: 20 }}
                    />{" "}
                    {supply_country_info?.name + " "}
                  </MDTypography>
                </MDBox>
                <BodyCSSTypo
                  title={` Recruiting  Agency : ${
                    recruiting_agency_info?.ra_name +
                    "-" +
                    recruiting_agency_info?.ra_code
                  }`}
                />
              </MDBox>
              <MDBox></MDBox>
            </MDBox>
            <MDBox
              mt={2}
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <MDButton
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => handleOpenDialog(dealItem)}
              >
                View
              </MDButton>
              {showEditButton && (
                <MDButton
                  variant="outlined"
                  size="small"
                  color="info"
                  onClick={() => navigate(`/dealdata/${deal_id}`)}
                >
                  Edit
                </MDButton>
              )}
              {showDeleteButton && (
                <MDButton variant="outlined" size="small" color="error">
                  Delete
                </MDButton>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </Grid>
    </Grid>
  );
}

// Setting default values for the props of DealRes
DealRes.defaultProps = {
  noGutter: false,
};

export default DealRes;
