import axios from "axios";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

// axios.defaults.headers.common["Authorization"] =
//   "Bearer " + JSON.parse(localStorage.getItem("user"));

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_URI_PATH,
});

export async function fetchData(url) {
  Axios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(
    localStorage.getItem("user")
  )}`;
  const response = await Axios.get(url)
    .then((res) => res.data)
    .catch((error) => {
      //console.log("err", error.response.data);
      const errData = error.response.data;
      return errData;
    });

  return response;
}

export async function postData(url, data) {
  Axios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(
    localStorage.getItem("user")
  )}`;
  const response = await Axios({
    method: "post",
    url,
    data,
  })
    .then((res) => res.data)
    .catch((error) => {
      //console.log("err", error.response.data);
      const errData = error.response.data;
      return errData;
    });

  return response;
}
export async function doUpload(url, data) {
  Axios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(
    localStorage.getItem("user")
  )}`;
  const response = await Axios({
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    url,
    data,
  })
    .then((res) => res.data)
    .catch((error) => {
      console.log("err", error.response.data);
      const errData = error.response.data;
      return errData;
    });

  return response;
}

export async function updateData(url, data) {
  Axios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(
    localStorage.getItem("user")
  )}`;
  const response = await Axios({
    method: "patch",
    url,
    data,
  })
    .then((res) => res.data)
    .catch((error) => {
      console.log(error.message);
      const errData = error.response.data;
      return errData;
    });

  return response;
}

export async function deleteData(url, data) {
  Axios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(
    localStorage.getItem("user")
  )}`;
  const response = await Axios({
    method: "delete",
    url,
    data,
  })
    .then((res) => res.data)
    .catch((error) => {
      console.log(error.message);
      const errData = error.response.data;
      return errData;
    });

  return response;
}

export function decimalBalance(num) {
  return Math.round(num);
}

// export function setSettingsData(store, data) {
//   const storeData = store.getState();
//   const st = !storeData.settings.settingsData._id && data && data.settings;
//   if (st) {
//     store.dispatch(updateSettings(data.settings));
//   }
// }

export function dateFormat(d) {
  return new Date(d).toLocaleDateString("en-IN", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function dateFormatMY(d) {
  return new Date(d).toLocaleDateString("en-IN", {
    year: "numeric",
    month: "short",
  });
}

export const reduceArray = async (data, id) => {
  return await data.reduce((accumulator, currentExpense) => {
    return accumulator + currentExpense.id;
  }, 0);
};

export const removeDuplicates = (array, key) => {
  const seen = {};
  return array.filter((item) => {
    const value = item[key];
    if (!seen[value]) {
      seen[value] = true;
      return true;
    }
    return false;
  });
};

export function dateTimeFormat(d) {
  return new Date(d).toLocaleDateString("en-IN", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
}

export function dateTimeStamp() {
  const currentDate = new Date();
  const timestamp = currentDate.toLocaleString().replace(/[\/:]/g, "-");
  return timestamp;
}

//Convert 24h time format to  12h time format
export function convertTo12HrsFormat(time) {
  // Check correct time format and split into components
  time = time?.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];
  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[3] = " "; //Remove 00 for seconds or just remove seconds
    time[0] = +time[0] % 12 || 12; // Adjust hours
    time[0] = convertToTwoDigit(time[0]);
  }
  return time.join(""); // return adjusted time or original string
}

export function convertTo24HrsFormat(time) {
  const slicedTime = time.split(/(PM|AM)/gm)[0];

  let [hours, minutes] = slicedTime.split(":");

  if (hours === "12") {
    hours = "00";
  }

  let updateHourAndMin;

  function addition(hoursOrMin) {
    updateHourAndMin =
      hoursOrMin.length < 2 ? (hoursOrMin = `${0}${hoursOrMin}`) : hoursOrMin;

    return updateHourAndMin;
  }

  if (time.endsWith("PM")) {
    hours = parseInt(hours, 10) + 12;
  }

  return `${addition(hours)}:${addition(minutes)}`;
}

function convertToTwoDigit(value) {
  value = value + "";
  return value.length == 1 ? "0" + value : value;
}

export function firstLetterCapital(string = null) {
  if (!string) {
    return;
  }
  string = string.replace(/['"]+/g, "").replace(/_/g, " ");
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function dateDifference(fromData, toDate = false) {
  const date1 = new Date(fromData);
  const date2 = new Date(toDate);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

export function toFixed(num) {
  return num.toFixed(2);
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export const settingDefault = [
  { value: 1, label: "Country" },
  { value: 2, label: "Industry" },
  { value: 3, label: "Tax rate" },
  { value: 4, label: "Industry Position" },
  { value: 5, label: "Size of the company" },
  { value: 6, label: "Religion" },
  { value: 7, label: "Lead source" },
  { value: 8, label: "Status" },
  { value: 9, label: "Task reason" },
  { value: 10, label: "Payment type" },
  { value: 11, label: "Currency type" },
  { value: 12, label: "Meeting reason" },
  { value: 13, label: "Call Report status" },
  { value: 14, label: "Booking type" },
  { value: 15, label: "Attestation type" },
  { value: 16, label: "Deployment type" },
];

export async function printDocument(reference, name) {
  const { jsPDF } = await import("jspdf");
  await toJpeg(reference, { quality: 1 })
    .then(function (dataUrl) {
      const pdf = new jsPDF("p", "pt", "a4", true);
      const imgProps = pdf.getImageProperties(dataUrl);

      const pdfWidth = pdf.internal.pageSize.getWidth() - 10 * 2.5;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      //console.log(`pdfWidth : ${pdfWidth} && pdfHeight : ${pdfHeight}`);

      pdf.addImage(dataUrl, "JPEG", 15, 10, pdfWidth, pdfHeight);
      pdf.save(`${name}.pdf`);
    })
    .catch((err) => {
      console.log(err);
    });
}
