// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import useSWR from "swr";
import { useEffect, useState, useRef } from "react";
//import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Tooltip,
} from "@mui/material";
//import { settingDefault } from "utils/clientFunctions";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import * as Yup from "yup";
import { updateData } from "utils/clientFunctions";

import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";

import { postData, doUpload } from "utils/clientFunctions";
import { ToastProvider, useToasts } from "react-toast-notifications";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import { useNavigate, useParams, Navigate, Link } from "react-router-dom";
import MDAvatar from "components/MDAvatar";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../../src/assets/css/Custom.css";
import { useFormik } from "formik";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { removeDuplicates } from "utils/clientFunctions";
import { invoiceSchema } from "../components/schemas";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import { isNumeric, toFixed } from "utils/clientFunctions";
import { dateFormat } from "utils/clientFunctions";
import { ToWords } from "to-words";
import { jsPDF } from "jspdf";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { printDocument } from "utils/clientFunctions";
import PrintInvoice from "../components/pdf";
import { creditNoteSchema } from "../components/schemas/creditNote";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function ViewInvoice() {
  const { id: invoiceId } = useParams();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  //Start : For permission
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);

  const [invoice, setInvoice] = useState([]);

  if (!invoiceId) {
    navigate("/invoice_list");
  }
  const [creditNoteData, setcreditNoteData] = useState({});
  if (invoiceId) {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("invEdit")
      )
    ) {
      navigate("/invoice_list");
    }
  } else {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("invCreate")
      )
    ) {
      navigate("/invoice_list");
    }
  }
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const getFetchData = async (url) => {
    return await fetchData(url);
  };

  useEffect(() => {
    getFetchData(`invoice/${invoiceId}`).then((res) => {
      setInvoice(res);
    });
  }, []);

  const currencyCode = invoice?.data?.br_currency_info?.currency_code;

  const html = invoice?.data?.br_terms_condition;

  const toWords = new ToWords();

  let totalInvoiceAmount =
    parseFloat(invoice?.data?.total_invoice_amount * 1) || 0;

  const tableHeader = {
    background: "linear-gradient(195deg, #078149, #078149)",
    color: "#fff",
    padding: "3px",
  };

  //START : Print Invoice
  const [invoiceData, setInvoiceData] = useState({});
  const [print, setPrint] = useState(false);
  const [download, setDownload] = useState(false);
  const [openPrintdialog, setOpenPrintdialog] = useState(false);
  const printPdfRef = useRef(null);
  const pageStyle = `
  @page {
    size: auto;
    margin: 8mm;
    font-size:50%;
  }
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  } 
`;
  // const handlePrint = useReactToPrint({
  //   content: () => printPdfRef.current,
  //   pageStyle,
  // });

  const handlePrint = () => {
    setPrint(true);
  };
  const handleDownload = () => {
    setDownload(true);
  };

  const handlePrintDownloadInvoice = (invoiceData, print, download) => {
    setInvoiceData(invoiceData); //Update the invoice props

    setOpenPrintdialog(true); //Open the print dialog

    setPrint(print); // If print is true then print the invoice
    setDownload(download); // If download is true then download the invoice

    // Close the print dialog & reset the  print & invoice
    setTimeout(
      () => {
        setOpenPrintdialog(false);
        setPrint(false);
        setDownload(false);
      },
      download ? 1000 : 100
    );
  };
  //END : Print Invoice

  //START : Download Invoice
  const downloadInvoice = async () => {
    await printDocument(printPdfRef.current, `Invoice-${Date.now()}`);
  };
  //END : Download Invoice

  //Dialog popup
  const [creditNoteFormData, setCreditNoteFormData] = useState({
    credit_note_number: "",
    credit_note_date: "",
    credit_note_amount: "",
    credit_note_remarks: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = (invoiceData) => {
    setcreditNoteData(invoiceData);
    let {
      invoice_number,
      br_currency_info,
      total_invoice_amount,
      credit_note_number,
      credit_note_date,
      credit_note_amount,
      credit_note_remarks,
    } = invoiceData;
    setCreditNoteFormData({
      credit_note_number,
      credit_note_date,
      credit_note_amount,
      credit_note_remarks,
    });
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setcreditNoteData({});
    setOpenDialog(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Formik>
          <Form>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-5}
                    py={1}
                    borderRadius="lg"
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    coloredShadow="info"
                  >
                    <MDTypography
                      variant="h6"
                      color="white"
                      style={{ display: "inline-block" }}
                    >
                      View Invoice
                    </MDTypography>

                    <MDButton
                      variant="outlined"
                      onClick={() => navigate(-1)}
                      color="white"
                      size="small"
                      style={{ float: "right", margin: "0 4px" }}
                    >
                      Back
                    </MDButton>
                    <MDButton
                      variant="outlined"
                      color="white"
                      size="small"
                      onClick={() =>
                        handlePrintDownloadInvoice(invoice.data, false, true)
                      }
                      style={{ float: "right", margin: "0 4px" }}
                    >
                      Download
                    </MDButton>
                    <MDButton
                      variant="outlined"
                      color="white"
                      size="small"
                      onClick={() =>
                        handlePrintDownloadInvoice(invoice.data, true, false)
                      }
                      style={{ float: "right", margin: "0 4px" }}
                    >
                      Print
                    </MDButton>

                    <MDButton
                      variant="outlined"
                      color="white"
                      size="small"
                      onClick={() => handleOpenDialog(invoice.data)}
                      style={{ float: "right", margin: "0 4px" }}
                    >
                      Credit Note
                    </MDButton>
                  </MDBox>
                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <PrintInvoice
                      data={invoice.data}
                      print={false}
                      download={false}
                    />

                    {openPrintdialog && (
                      <PrintInvoice
                        data={invoice.data}
                        print={print}
                        download={download}
                      />
                    )}
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </MDBox>
      <CreditNoteDialog
        openDialog={openDialog}
        creditNoteData={creditNoteData}
        handleCloseDialog={handleCloseDialog}
        invoiceId={invoiceId}
        creditNoteFormData={creditNoteFormData}
        addToast={addToast}
      />
      <Footer />
    </DashboardLayout>
  );
}

const CreditNoteDialog = ({
  openDialog,
  creditNoteData,
  handleCloseDialog,
  invoiceId,
  creditNoteFormData,
  addToast,
}) => {
  let {
    invoice_number,
    br_currency_info,
    total_invoice_amount,
    credit_note_number,
    credit_note_date,
    credit_note_amount,
    credit_note_remarks,
  } = creditNoteData;

  // setCreditNoteFormData({
  //   credit_note_number,
  //   credit_note_date,
  //   credit_note_amount,
  //   credit_note_remarks,
  // });

  //console.log("creditNoteData", creditNoteData);
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: creditNoteFormData,
    enableReinitialize: true,
    validationSchema: creditNoteSchema,
    onSubmit: (values, action) => {
      handleCreditNoteFormSubmit(values, action);
    },
  });

  const commaonError = (fieldName) => {
    return errors?.[fieldName] && touched?.[fieldName] ? (
      <p className="formError">{errors[fieldName]}</p>
    ) : null;
  };

  const data = {
    credit_note_number: values.credit_note_number,
    credit_note_date: values.credit_note_date,
    credit_note_amount: values.credit_note_amount,
    credit_note_remarks: values.credit_note_remarks,
  };
  const handleCreditNoteFormSubmit = async () => {
    const creditUrl = `/invoice/update-credit-note/${invoiceId}`;
    const response = await updateData(creditUrl, data);
    if (!response.status) {
      if (response.message) {
        addToast(response.message, { appearance: "error", autoDismiss: true });
      }
      if (response.errors) {
        let apiErrors = response.errors;
        for (let i = 0; i < apiErrors.length; i++) {
          let errorMessage = apiErrors[i].message;
          errorMessage = errorMessage.replace(/['"]+/g, "").replace(/_/g, " ");
          errorMessage =
            errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
          addToast(errorMessage, { appearance: "error", autoDismiss: true });
        }
      }
    } else {
      addToast(response.message, {
        appearance: "success",
        autoDismiss: true,
      });
      handleCloseDialog();
      //navigate(-1);
    }
  };

  return (
    <BootstrapDialog
      onClose={handleCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
      fullScreen={window.innerWidth < 768 ? true : false}
    >
      <DialogTitle
        sx={{ m: 0, textAlign: "center" }}
        id="customized-dialog-title"
      >
        Credit Note - {invoice_number}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCloseDialog}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Invoice Number"
                  fullWidth
                  disabled
                  value={invoice_number}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  disabled
                  label="Invoice Amount"
                  fullWidth
                  value={
                    br_currency_info?.currency_code + " " + total_invoice_amount
                  }
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={12}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  name="credit_note_number"
                  label="Credit Note Number"
                  fullWidth
                  value={values.credit_note_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {commaonError("credit_note_number")}
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox mb={2}>
                <MDInput
                  type="date"
                  name="credit_note_date"
                  label="Credit Note Date"
                  fullWidth
                  value={values.credit_note_date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {commaonError("credit_note_date")}
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  name="credit_note_amount"
                  label="Credit Note Amount"
                  fullWidth
                  value={values.credit_note_amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {commaonError("credit_note_amount")}
              </MDBox>
            </Grid>

            <Grid item xs={12} md={12}>
              <MDBox mb={2}>
                <TextField
                  id="outlined-multiline-static"
                  label="Credit Note Remarks *"
                  name="credit_note_remarks"
                  InputLabelProps={{ shrink: true }}
                  multiline
                  fullWidth
                  rows={3}
                  value={values.credit_note_remarks}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {commaonError("credit_note_remarks")}
              </MDBox>
            </Grid>

            <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
              <MDBox alignItems="center" style={{ textAlign: "center" }}>
                <MDButton
                  variant="gradient"
                  color="primary"
                  size="small"
                  type="submit"
                  className="saveButton"
                >
                  Save
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCloseDialog}>
          Close
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default ViewInvoice;
