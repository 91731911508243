// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import useSWR from "swr";
import { useEffect, useState, useRef } from "react";

import { fetchData } from "utils/clientFunctions";

import { useNavigate, useParams, Navigate, Link } from "react-router-dom";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useFormik, Field, Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import { CardTopBoxBack } from "layouts/commonComponents/cardTopBoxBack";
import DataTable from "react-data-table-component";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { firstLetterCapital } from "utils/clientFunctions";
import { dateFormat } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import { CSVDownload, CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { dateTimeFormat } from "utils/clientFunctions";
import { dateTimeStamp } from "utils/clientFunctions";
function CreditReport() {
  const csvLink = useRef();

  const getFetchData = async (url) => {
    return await fetchData(url);
  };
  const [searchFormData, setSearchFormData] = useState({
    from_date: "",
    to_date: "",
  });
  const [report, setReport] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  useEffect(() => {
    getReport();
  }, [pageIndex, searchFormData]);
  const getReport = async () => {
    let queryUrl = getQueryParams();
    const res = await getFetchData(`report/credit_note${queryUrl}`);
    console.log("res", res.data);
    if (res && res.data) {
      setPageCount(Math.ceil(res.count / 10));
      setReport(res.data || []);
    }
  };

  const getQueryParams = () => {
    let queryUrl = `?page=${pageIndex}`;
    queryUrl = searchFormData.from_date
      ? queryUrl + "&from_date=" + searchFormData.from_date
      : queryUrl;
    queryUrl = searchFormData.to_date
      ? queryUrl + "&to_date=" + searchFormData.to_date
      : queryUrl;
    return queryUrl;
  };

  const columns = [
    {
      name: "Number",
      selector: (row) => row.credit_note_number,
    },
    {
      name: "Date",
      selector: (row) =>
        row.credit_note_date ? dateFormat(row.credit_note_date) : "-",
    },
    {
      name: "Amount",
      selector: (row) =>
        row?.br_currency_info?.currency_code + " " + row.credit_note_amount,
    },
    {
      name: "Bill to Name",
      selector: (row) => row.bill_name,
    },
    {
      name: "Invoice Number",
      selector: (row) => row.invoice_number,
    },
    {
      name: "Invoice Amount",
      selector: (row) =>
        row?.br_currency_info?.currency_code + " " + row.total_invoice_amount,
    },
    {
      name: "Invoice Date",
      selector: (row) =>
        row.invoice_date ? dateFormat(row.invoice_date) : "-",
    },
  ];
  const data = report;
  const doPaginationChange = (event, value) => {
    setPageIndex(value);
  };

  const onChangeSearch = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setSearchFormData({ ...searchFormData, [name]: val });
  };

  const [csvData, setCsvData] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);
  let csvHeaders = [
    { label: "Credit note number", key: "credit_note_number" },
    { label: "Credit note date", key: "credit_note_date" },
    {
      label: "Credit note amount",
      key: "credit_note_amount",
    },
    { label: "Bill to Name", key: "bill_name" },
    { label: "Invoice number", key: "invoice_number" },
    { label: "Invoice amount", key: "total_invoice_amount" },
    { label: "Invoice date", key: "invoice_date" },
  ];
  const exportCsv = async () => {
    let queryUrl = getQueryParams();
    queryUrl = queryUrl + `&download=1`;
    const res = await getFetchData(`report/credit_note${queryUrl}`);
    if (res?.data) {
      let exportData = [];
      await Promise.all(
        res?.data.map((report) => {
          report.credit_note_date = report.credit_note_date
            ? dateFormat(report.credit_note_date)
            : "-";
          report.invoice_date = report.invoice_date
            ? dateFormat(report.invoice_date)
            : "-";
          report.credit_note_amount = report.credit_note_amount
            ? report.br_currency_info?.currency_code +
              " " +
              report.credit_note_amount
            : "-";
          report.total_invoice_amount = report.total_invoice_amount
            ? report.br_currency_info?.currency_code +
              " " +
              report.total_invoice_amount
            : "-";
          exportData.push(report);
        })
      );
      setCsvData(exportData); //Set the csv data
      setDownloadCsv(true); // Show the CSVDwonload link
      setTimeout(() => {
        csvLink?.current?.link?.click();
        setDownloadCsv(false); // Hide the CSVDwonload link
      }, 100);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <CardTopBoxBack
                title={"Credit Note Report"}
                showBackButton={false}
              />
              <MDBox
                variant="contained"
                bgColor="white"
                color="black"
                borderRadius="md"
                shadow="md"
              >
                <Grid container p={2} spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Formik>
                      <Form>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={3}></Grid>
                          <Grid item xs={12} md={2}>
                            <MDBox mb={2}>
                              <MDInput
                                name="from_date"
                                type="date"
                                label="From Date"
                                fullWidth
                                value={searchFormData.from_date}
                                onChange={onChangeSearch}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <MDBox mb={2}>
                              <MDInput
                                name="to_date"
                                type="date"
                                label="To Date"
                                fullWidth
                                value={searchFormData.to_date}
                                onChange={onChangeSearch}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <CSVLink
                              className=" d-none "
                              data={csvData}
                              filename={`Credit Note Reports ${dateTimeStamp()}`}
                              headers={csvHeaders}
                              ref={csvLink}
                              target="_self"
                            />

                            <MDBox display="flex" alignItems="center">
                              <MDButton
                                variant="gradient"
                                color="primary"
                                size="small"
                                type="button"
                                className="saveButton"
                                onClick={exportCsv}
                              >
                                <DownloadIcon /> Export CSV
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Form>
                    </Formik>
                    <DataTable columns={columns} data={data} />
                    <Grid item xs={12} md={12}>
                      <Stack spacing={2} mb={2} ml={2}>
                        <Pagination
                          count={pageCount}
                          page={pageIndex}
                          onChange={doPaginationChange}
                          variant="outlined"
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CreditReport;
