// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import useSWR from "swr";
import { useEffect, useState } from "react";
//import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import { DialogContent } from "@mui/material";
//import { settingDefault } from "utils/clientFunctions";
import { Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";

import { postData, doUpload } from "utils/clientFunctions";
import { useToasts } from "react-toast-notifications";

import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../../src/assets/css/Custom.css";
import { useFormik } from "formik";
import { createMeetingSchema } from "../components/schemas";
import { CardTopBoxBack } from "layouts/commonComponents/cardTopBoxBack";
import { updateData } from "utils/clientFunctions";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function MeetingData() {
  let { id } = useParams();
  const [userList, setUserList] = useState([]);
  const [reasonList, setreasonList] = useState([]);
  const [statusList, setstatusList] = useState([]);
  const { addToast } = useToasts();
  const navigate = useNavigate();

  //Start : For permission
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);
  if (id) {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("metEdit")
      )
    ) {
      navigate("/meeting");
    }
  } else {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("metCreate")
      )
    ) {
      navigate("/meeting");
    }
  }
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const [formData, setFormData] = useState({
    user_id: "",
    reason_id: "",
    vendor_type: "",
    meeting_with: "",
    meeting_mode: "",
    meeting_date: "",
    start_time: "",
    end_time: "",
    status_id: "",
    remarks: "",
    attendees: [],
  });

  const searchSpot = (value, key) => {
    liveSearchData({
      countryId: null,
      value,
      key,
      setUserList,
    });
  };

  const getFetchData = async (settingUrl) => {
    return await fetchData(settingUrl);
  };

  //Get data
  useEffect(() => {
    if (id) {
      const updateUrl = `meeting/${id}`;
      getFetchData(updateUrl).then((res) => {
        let {
          user_info,
          status_info,
          reason_info,
          vendor_type,
          meeting_with,
          meeting_mode,
          meeting_date,
          start_time,
          end_time,
          remarks,
          attendees_list,
        } = res.data;

        let newVendorType = vendorLeadOptions?.filter(
          (tp) => tp.value === vendor_type
        );

        let updatedAttendeesList = [];

        if (attendees_list) {
          attendees_list?.map((user) => {
            updatedAttendeesList.push(user.attendee_info);
          });
        }

        setFormData({
          user_id: user_info,
          reason_id: reason_info,
          vendor_type: newVendorType && newVendorType[0],
          meeting_with,
          meeting_mode,
          meeting_date,
          start_time,
          end_time,
          status_id: status_info,
          remarks,
          attendees: updatedAttendeesList,
          // start_date_time:
          //   start_date_time &&
          //   new Date(start_date_time).toLocaleString("sv").slice(0, 16),
          // end_date_time:
          //   end_date_time &&
          //   new Date(end_date_time).toLocaleString("sv").slice(0, 16),
        });
      });
    }
    const userUrl = `user/list?page=1`;
    getFetchData(userUrl).then((res) => {
      let userList = res.data;
      setUserList(userList);
    });

    const reasonUrl = `setting/type/12`;
    getFetchData(reasonUrl).then((res) => {
      let reasonList = res.data;
      setreasonList(reasonList);
    });

    const statusUrl = `setting/type/8`;
    getFetchData(statusUrl).then((res) => {
      let statusList = res.data;
      setstatusList(statusList);
    });
  }, []);

  const handleFormSubmit = async (data, resetForm) => {
    let submitData = {};
    submitData = data;

    //Update below fields to send only ids

    submitData.start_time =
      data.start_time.length === 8 ? data.start_time : data.start_time + ":00";
    submitData.end_time =
      data.end_time.length === 8 ? data.end_time : data.end_time + ":00";
    submitData.vendor_type = data.vendor_type?.value;
    submitData.user_id = data.user_id?.user_id;
    submitData.reason_id = data.reason_id?.setting_id;
    submitData.status_id = data.status_id?.setting_id;

    // Convert attendees(Users) list into array
    let allUsers = [];
    if (data.attendees.length) {
      data?.attendees?.map((user) => {
        allUsers.push(user.user_id);
      });
    }
    submitData.attendees = allUsers;

    // console.log("submitData", submitData);
    // return false;
    const url = `meeting`;
    const response = id
      ? await updateData(`meeting/${id}`, submitData)
      : await postData(url, submitData);
    if (!response.status) {
      if (response.message) {
        addToast(response.message, { appearance: "error", autoDismiss: true });
      }
      if (response.errors) {
        let apiErrors = response.errors;
        for (let i = 0; i < apiErrors.length; i++) {
          let errorMessage = apiErrors[i].message;
          errorMessage = errorMessage.replace(/['"]+/g, "").replace(/_/g, " ");
          errorMessage =
            errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
          addToast(errorMessage, { appearance: "error", autoDismiss: true });
        }
      }
    } else {
      let successMessage = id
        ? "Meeting has been updated successfully"
        : "New meeting has been created successfully";
      addToast(successMessage, {
        appearance: "success",
        autoDismiss: true,
      });
      navigate(-1);
    }
  };

  const ErrorDisp = (name) => {
    console.log(errors?.[name]); // Use `errors?.[name]` to access the dynamic field name
    return (
      errors[name] &&
      touched[name] && ( // Use `errors[name]` instead of `errors.name`
        <p className="formError">{errors[name]}</p>
      )
    );
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema: createMeetingSchema,
    onSubmit: ({ resetForm }) => {
      handleFormSubmit(values, resetForm);
    },
  });
  const vendorLeadOptions = [
    { label: "Recruiting agency", value: "ra" },
    { label: "Company", value: "company" },
    { label: "Applicant", value: "applicant" },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Formik
          initialValues={formData}
          enableReinitialze={true}
          onSubmit={handleSubmit}
        >
          <Form>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <CardTopBoxBack
                    title={id ? "Update meeting" : "New meeting"}
                  />
                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.user_id}
                                  size="small"
                                  name="user_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("user_id", newValue)
                                      : setFieldValue("user_id", "")
                                  }
                                  options={userList}
                                  getOptionLabel={(userList) =>
                                    (userList &&
                                      userList.first_name +
                                        " " +
                                        userList.last_name) ||
                                    ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Organised By *"
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(e) =>
                                        searchSpot("user", e.target.value)
                                      }
                                    />
                                  )}
                                />
                                {ErrorDisp("user_id")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.reason_id}
                                  size="small"
                                  name="reason_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("reason_id", newValue)
                                      : setFieldValue("reason_id", "")
                                  }
                                  options={reasonList}
                                  getOptionLabel={(reasonList) =>
                                    reasonList.setting_value || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Agenda *"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("reason_id")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.vendor_type}
                                  size="small"
                                  name="vendor_type"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("vendor_type", newValue)
                                      : setFieldValue("vendor_type", "")
                                  }
                                  options={vendorLeadOptions}
                                  required
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Vendor Type *"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("vendor_type")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="meeting_with"
                                  type="text"
                                  label="Meeting with *"
                                  fullWidth
                                  value={values.meeting_with}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("meeting_with")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="meeting_mode"
                                  type="text"
                                  label="Meeting Mode *"
                                  fullWidth
                                  value={values.meeting_mode}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("meeting_mode")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={9}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  multiple
                                  value={values.attendees}
                                  id="checkboxes-tags-demo"
                                  size="small"
                                  name="attendees"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("attendees", newValue)
                                      : setFieldValue("attendees", "")
                                  }
                                  isOptionEqualToValue={(option, value) =>
                                    option.user_id === value.user_id
                                  }
                                  options={userList}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) =>
                                    option.first_name + " " + option.last_name
                                  }
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.first_name +
                                        " " +
                                        option.last_name}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Attendees"
                                      placeholder="Select Attendees"
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(e) =>
                                        searchSpot("user", e.target.value)
                                      }
                                    />
                                  )}
                                />
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="meeting_date"
                                  type="date"
                                  label="Meeting Date *"
                                  fullWidth
                                  value={values.meeting_date}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("meeting_date")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="start_time"
                                  type="time"
                                  label="Start Time *"
                                  fullWidth
                                  value={values.start_time}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("start_time")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="end_time"
                                  type="time"
                                  label="End Time *"
                                  fullWidth
                                  value={values.end_time}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("end_time")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.status_id}
                                  size="small"
                                  name="status_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("status_id", newValue)
                                      : setFieldValue("status_id", "")
                                  }
                                  options={statusList}
                                  getOptionLabel={(statusList) =>
                                    statusList.setting_value || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Status *"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("status_id")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <MDBox mb={2}>
                                <TextField
                                  id="outlined-multiline-static"
                                  label="Remarks *"
                                  name="remarks"
                                  InputLabelProps={{ shrink: true }}
                                  multiline
                                  fullWidth
                                  rows={3}
                                  value={values.remarks}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("remarks")}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
            <MDBox mt={2} display="flex" alignItems="center">
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                type="submit"
                className="saveButton"
              >
                Save
              </MDButton>
            </MDBox>
          </Form>
        </Formik>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MeetingData;
