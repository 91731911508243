import * as Yup from "yup";

export const CreateApplicantSchema = Yup.object({
  area: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please select area")
    .max(255),
  image: Yup.mixed().required("Please select image"),
  salutation: Yup.mixed().required("Please select salutation"),
  first_name: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter first name as per Passport")
    .max(255),
  //   middle_name: Yup.string()
  //     .min(2, "Please enter minimum two characters")
  //     .required("Please enter middle name")
  //     .max(255),
  last_name: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter last name as per Passport")
    .max(255),
  date_of_birth: Yup.date().required("Please provide date of birth"),

  passport_number: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter passport number")
    .max(50),
  issue_country_id: Yup.mixed().required("Please select issue country"),
  issue_date: Yup.date().required("Please provide issue date"),
  expiry_date: Yup.date().required("Please provide expiry date"),

  religion_id: Yup.mixed().required("Please select religion"),
  mobile_code_id: Yup.mixed().required("Please select country"),
  mobile_number: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter mobile number")
    .max(20),
  email_id: Yup.string()
    .required("Please enter email")
    .email("Please enter valid email")
    .max(255),
  whatsapp_code_id: Yup.mixed().required("Please select country"),
  whatsapp_number: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter whatsapp number")
    .max(255),
  company_id: Yup.mixed().required("Please select company"),
  position_id: Yup.mixed().required("Please select position"),
  source_of_lead_type: Yup.mixed().required(
    "Please select type of lead source"
  ),
  source_of_lead_id: Yup.mixed().required("Please select name of lead source"),
  emergency_code_id: Yup.mixed().required("Please select country"),
  emergency_contact_number: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter emergency contact number")
    .max(255),
  emergency_contact_name: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter emergency contact name")
    .max(20),
  emergency_contact_relation: Yup.string()
    .min(2)
    .required("Please enter emergency contact relation")
    .max(20),
  address: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter address")
    .max(250),
  landmark: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter landmark")
    .max(250),
  postal_code: Yup.number()
    .typeError("Postal code must be a number")
    .integer("Please enter postal code")
    .required("Please enter postal code"),
  city: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter city")
    .max(250),
  state: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter state")
    .max(250),
  country_id: Yup.mixed().required("Please select country"),

  // deployment_visa_issue_date: Yup.date().required(
  //   "Please provide visa issue date"
  // ),
  // deployment_visa_expiry_date: Yup.date().nullable(),
  // deployment_date: Yup.mixed().when("deployment_visa_expiry_date", (value) => {
  //   if (
  //     value[0] === "" ||
  //     value[0] === null ||
  //     typeof value[0] == "undefined"
  //   ) {
  //     console.log("TRUE: deployment");
  //     return Yup.date().required(
  //       "Please provide deployment date / visa expiry date"
  //     );
  //   } else {
  //     console.log("FALSE: deployment");
  //     return Yup.mixed()
  //       .transform((value, originalValue) => {
  //         if (!value) {
  //           return null;
  //         }
  //         return originalValue;
  //       })
  //       .nullable()
  //       .optional();
  //   }
  // }),
  // deployment_fine_payment: Yup.number().typeError("Enter valid fine payment"),
  // deployment_type_id: Yup.mixed().required("Please select deployment type"),
});
