import { postData } from "./clientFunctions";
import { createContext } from "react";

const getPermissionApiData = async () => {
  let response = await postData(`user/verify`);
  return response?.data;
};

const result = await getPermissionApiData();

export const PermissionContext = createContext(result);
