import * as Yup from "yup";

export const createStaffSchema = Yup.object({
  user_type: Yup.mixed().required("Please select user type"),
  first_name: Yup.string()
    .min(2, "First name should be at least 2 characters")
    .required("Please enter your first name"),
  last_name: Yup.string()
    .min(2, "Last name should be at least 2 characters")
    .required("Please enter your last name"),
  mobile_number: Yup.string()
    .matches(/^\d{10}$/, "Please enter a valid 10-digit mobile number")
    .required("Please enter your mobile number"),
  email_id: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter your email"),
  password: Yup.string()
    .min(6, "Password should be at least 6 characters")
    .required("Please enter a password"),
});

export const updateStaffSchema = Yup.object({
  user_type: Yup.mixed().required("Please select user type"),
  first_name: Yup.string()
    .min(2, "First name should be at least 2 characters")
    .required("Please enter your first name"),
  last_name: Yup.string()
    .min(2, "Last name should be at least 2 characters")
    .required("Please enter your last name"),
  mobile_number: Yup.string()
    .matches(/^\d{10}$/, "Please enter a valid 10-digit mobile number")
    .required("Please enter your mobile number"),
});
