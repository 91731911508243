import * as Yup from "yup";

export const createRaSchema = Yup.object({
  ra_name: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter RA name")
    .max(120),
  ra_code: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter RA code")
    .max(120),
  supply_country_id: Yup.mixed().required("Please select valid country"),
  industry_id: Yup.mixed().required("Please select valid industry"),
  //receiving_country_id: Yup.mixed().required('Please select valid country'),
  logo: Yup.mixed().required("Please upload a png/jpeg image"),
  licence_number: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter licence number")
    .max(120),
  number_of_working_staff: Yup.number()
    .typeError("Only numbers allowed")
    .integer()
    .required("Please enter number of working staff"),
  firm_type: Yup.mixed().required("Please select valid firm type"),
  //owner_partner_details: Yup.string().min(2, "Please enter minimum two characters").required("Please enter owner partner details").max(120),
  //contact_country_code_id: Yup.mixed().required('Please select valid code'),
  //contact_number: Yup.number().typeError('Only numbers allowed').min(2, "Please enter minimum two characters").required("Please enter contact number"),
  //email_id: Yup.string().email("Please enter valid email id").required("Please enter email id"),
  address: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter address")
    .max(250),
  landmark: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter landmark")
    .max(250),
  postal_code: Yup.number()
    .typeError("Postal code must be a number")
    .integer("Please enter postal code")
    .required("Please enter postal code"),
  city: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter city")
    .max(250),
  state: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter state")
    .max(250),
  country_id: Yup.mixed().required("Please select valid country"),
  landline_country_code_id: Yup.mixed().required("Please select valid code"),
  landline_number: Yup.number()
    .typeError("Only numbers allowed")
    .min(2, "Please enter minimum two characters")
    .required("Please enter landline number"),
  // other_email_ids: Yup.array().items(Yup.string().email()),
});
