import * as Yup from "yup";

export const callReportSchema = Yup.object({
  party_type: Yup.mixed().required("Please select party type"),

  //party_id: Yup.mixed().required("Please select party"),

  party_id: Yup.mixed().when("party_type.value", (value) => {
    if (value[0] !== "other") {
      return Yup.mixed().required("Please select party");
    } else {
      return Yup.mixed()
        .transform((value, originalValue) => {
          if (!value) {
            return null;
          }
          return originalValue;
        })
        .nullable()
        .optional();
    }
  }),

  party_entered_name: Yup.mixed().when("party_type.value", (value) => {
    if (value[0] === "other") {
      return Yup.string()
        .min(2, "Please enter minimum two characters")
        .required("Please enter person name")
        .max(120, "Max 120 characters allowed");
    } else {
      return Yup.mixed()
        .transform((value, originalValue) => {
          if (!value) {
            return null;
          }
          return originalValue;
        })
        .nullable()
        .optional();
    }
  }),

  person_name: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter person name")
    .max(120),
  person_position: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter person position")
    .max(120),
  mobile_code_id: Yup.mixed().required("Please select mobile code"),
  mobile_number: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter mobile number")
    .max(20),
  landline_code_id: Yup.mixed().required("Please select landline code"),
  landline_number: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter landline number")
    .max(20),
  email_id: Yup.string()
    .required("Please enter email")
    .email("Please enter valid email")
    .max(255),
  call_date_time: Yup.date().required("Please provide call date time"),
  status_id: Yup.mixed().required("Please select status"),
  remarks: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter remarks")
    .max(250, "Maximum 250 characters allowed"),
});
