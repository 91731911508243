import * as Yup from "yup";

export const createTaskSchema = Yup.object({
  remarks: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter remarks")
    .max(250, "Maximum 250 characters allowed"),
  start_date: Yup.date().required("Please provide start date"),
  end_date: Yup.date().required("Please provide end date"),
  country_id: Yup.mixed().required("Please select country"),
  reason_id: Yup.mixed().required("Please select lead"),
  company_id: Yup.mixed().required("Please select industry"),
  priority: Yup.mixed().required("Please select priority"),
  assigned_user_id: Yup.mixed().required("Please select assigned"),
  status_id: Yup.mixed().required("Please select status"),
  name: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter task name")
    .max(250, "Maximum 250 characters allowed"),
});
