// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { Grid, IconButton } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import { useNavigate } from "react-router-dom";
import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";

function Bill({
  companyItem,
  handleOpenDialog,
  index,
  showEditButton,
  showDeleteButton,
}) {
  let {
    company_id,
    company_code,
    name,
    logo,
    country,
    industry,
    company_size,
  } = companyItem;
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <MDBox
          borderRadius="lg"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={1}
          sx={{
            border: ({ borders: { borderWidth, borderColor } }) =>
              `${borderWidth[1]} solid ${borderColor}`,
          }}
        >
          <MDBox width="100%" display="flex" flexDirection="column">
            <MDBox
              mb={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="caption" fontWeight="bold">
                {index}
              </MDTypography>
              <MDTypography variant="caption" fontWeight="bold">
                {company_code}
              </MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "center", sm: "center" }}
              flexDirection={{ xs: "row", sm: "row" }}
              // mb={2}
            >
              <MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="bold">
                    {name}
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="bold">
                    {country?.name}
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="bold">
                    {industry?.setting_value}
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="bold">
                    {company_size?.setting_value}
                  </MDTypography>
                </MDBox>
              </MDBox>

              <MDAvatar
                src={process.env.REACT_APP_URI_PATH + logo}
                name={name}
                size="md"
                sx={{ width: 56, height: 56, marginLeft: "auto" }}
                variant="rounded"
              />

              <MDBox></MDBox>
            </MDBox>
            <MDBox
              mt={2}
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <MDButton
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => handleOpenDialog(companyItem)}
              >
                View
              </MDButton>

              {showEditButton && (
                <MDButton
                  variant="outlined"
                  size="small"
                  color="info"
                  onClick={() => navigate(`/create_company/${company_id}`)}
                >
                  Edit
                </MDButton>
              )}
              {showDeleteButton && (
                <MDButton variant="outlined" size="small" color="error">
                  Delete
                </MDButton>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </Grid>
    </Grid>
  );
}

// Setting default values for the props of Bill
Bill.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
Bill.propTypes = {
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  vat: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Bill;
