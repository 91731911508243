import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";

export const CardTopBoxBack = ({ title, showBackButton = true }) => {
  const navigate = useNavigate();
  return (
    <MDBox
      mx={2}
      mt={-5}
      py={1}
      borderRadius="lg"
      px={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      variant="gradient"
      bgColor="info"
      coloredShadow="info"
    >
      <MDTypography variant="h6" color="white">
        {title}
      </MDTypography>
      {showBackButton && (
        <MDButton
          variant="outlined"
          onClick={() => navigate(-1)}
          color="white"
          size="small"
        >
          Back
        </MDButton>
      )}
    </MDBox>
  );
};
