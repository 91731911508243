// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";
import { useEffect, useRef, useState } from "react";
import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Tooltip,
} from "@mui/material";
import { settingDefault } from "utils/clientFunctions";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MDAvatar from "components/MDAvatar";
import { Delete, Edit, RemoveRedEye, TramSharp } from "@mui/icons-material";
import { useAuth } from "utils/auth";
import pxToRem from "assets/theme/functions/pxToRem";
import MobileList from "../components/mobileList";
import { dateFormat } from "utils/clientFunctions";
import { CardTopBoxAdd } from "layouts/commonComponents/cardTopBoxAdd";
import { dateTimeFormat } from "utils/clientFunctions";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import { firstLetterCapital } from "utils/clientFunctions";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CSVDownload, CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { dateTimeStamp } from "utils/clientFunctions";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function CallReportList() {
  const csvLink = useRef();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageResize, setPageResize] = useState(
    window.innerWidth < 992 ? true : false
  );
  const navigate = useNavigate();
  //Start : For permission
  const dispatch = useDispatch();
  var permissions = useSelector(selectPermissions);
  if (
    !(
      permissions?.user_id === 1 || permissions?.permission?.includes("clrView")
    )
  ) {
    navigate("/dashboard");
  }
  let showAddButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("clrCreate")
      ? true
      : false;
  let showEditButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("clrEdit")
      ? true
      : false;
  let showDeleteButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("clrDelete")
      ? true
      : false;
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission
  const [callReportList, setCallReportList] = useState([]);

  const [statusList, setStatusList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [applicantList, setApplicantList] = useState([]);
  const [raList, setRaList] = useState([]);
  const [usersList, setUserList] = useState([]);

  //View More RA data
  const [callReportData, setcallReportData] = useState({});
  const { logout } = useAuth();

  const initialLoadData = async () => {
    let queryUrl = getQueryParams();
    const url = `call_report/list${queryUrl}`;
    const data = await fetchData(url);
    if (
      data?.name === "JsonWebTokenError" ||
      data?.name === "TokenExpiredError" ||
      data?.message === "You are not authorized"
    ) {
      logout();
    }
    if (data && data.data) {
      setPageCount(Math.ceil(data.count / 10));
      setCallReportList(data.data || []);
    }
  };

  useEffect(() => {
    const statusUrl = `setting/type/13`;
    getFetchData(statusUrl).then((res) => {
      setStatusList(res.data);
    });

    const companyUrl = `company/list?page=1`;
    getFetchData(companyUrl).then((res) => {
      setCompanyList(res.data);
    });

    const applicantUrl = `applicant/list?page=1`;
    getFetchData(applicantUrl).then((res) => {
      setApplicantList(res.data);
    });

    const raUrl = `recruiting_agency/list?page=1`;
    getFetchData(raUrl).then((res) => {
      setRaList(res.data);
    });

    const userUrl = `user/list?page=1`;
    getFetchData(userUrl).then((res) => {
      setUserList(res.data);
    });
  }, []);

  const searchSpot = (value, key) => {
    liveSearchData({
      countryId: null,
      value,
      key,
      setRaList,
      setCompanyList,
      setApplicantList,
      setUserList,
    });
  };

  //Search & Filter initial data
  const [searchFormData, setSearchFormData] = useState({
    party_type: "",
    party_id: "",
    party_entered_name: "",
    person_name: "",
    status_id: "",
  });
  useEffect(() => {
    initialLoadData();
  }, [
    pageIndex,
    searchFormData.party_type,
    searchFormData.party_id,
    searchFormData.party_entered_name,
    searchFormData.person_name,
    searchFormData.status_id,
  ]);

  const doPaginationChange = (event, value) => {
    setPageIndex(value);
  };

  //Dialog popup
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = (callReportInfo) => {
    setcallReportData(callReportInfo);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setcallReportData({});
    setOpenDialog(false);
  };

  window.addEventListener("resize", () => {
    if (window.innerWidth < 992) {
      setPageResize(true);
    } else {
      setPageResize(false);
    }
  });

  const getFetchData = async (settingUrl) => {
    return await fetchData(settingUrl);
  };

  const displayTypoInfo = (label, value) => {
    return (
      <>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="medium">
            {label}
          </MDTypography>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="regular">
            {value}
          </MDTypography>
        </Grid>
      </>
    );
  };

  const ViewMoreRa = ({ openDialog, callReportData, handleCloseDialog }) => {
    let {
      call_report_id,
      person_name,
      person_position,
      mobile_number,
      landline_number,
      email_id,
      call_date_time,
      remarks,
      party_type,
      party_id,
      party_entered_name,
      party_name,
      status_value,
      mobile_code_dial_code,
      landline_code_dial_code,
    } = callReportData;

    return (
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        fullScreen={window.innerWidth < 768 ? true : false}
      >
        <DialogTitle sx={{ m: 0 }} id="customized-dialog-title">
          Call Report Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container p={2} spacing={1}>
            {displayTypoInfo(
              "Party Type",
              party_type === "ra"
                ? "Recruiting Agency"
                : firstLetterCapital(party_type)
            )}
            {displayTypoInfo(
              "Party Name",
              party_type === "other" ? party_entered_name : party_name
            )}
            {displayTypoInfo("Person Name", person_name)}
            {displayTypoInfo("Person Position", person_position)}
            {displayTypoInfo(
              "Mobile Number",
              mobile_code_dial_code + " " + mobile_number
            )}
            {displayTypoInfo(
              "Landline Number",
              landline_code_dial_code + " " + landline_number
            )}
            {displayTypoInfo("E-mail Id", email_id)}
            {displayTypoInfo(
              "Call Date Time",
              call_date_time ? dateTimeFormat(call_date_time) : ""
            )}
            {displayTypoInfo("Status", status_value)}
            {displayTypoInfo("Remarks", remarks)}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    );
  };

  //START : Search and Filter form data
  //Handle onchange search and filter
  const onChangeSearch = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setSearchFormData({ ...searchFormData, [name]: val });
  };
  const [csvData, setCsvData] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);

  let csvHeaders = [
    { label: "Party Type", key: "party_type" },
    { label: "Party Name", key: "party_name" },
    { label: "Person Name", key: "person_name" },
    { label: "Person Position", key: "person_position" },
    { label: "Mobile Number ", key: "mobile_number" },
    { label: "E-mail Id", key: "email_id" },
    { label: "Call Date Time", key: "call_date_time" },
    { label: "Status", key: "status_value" },
    ,
  ];
  //Prepare the csv data for export
  const exportCsv = async () => {
    let queryUrl = getQueryParams();
    queryUrl = queryUrl + `&download=1`;
    getFetchData(`call_report/list${queryUrl}`).then(async (res) => {
      if (res?.data) {
        let exportData = [];
        await Promise.all(
          res?.data.map((report) => {
            report.party_type =
              report?.party_type === "ra"
                ? "Recruiting Agency"
                : firstLetterCapital(report?.party_type);

            report.mobile_number =
              report?.mobile_code_dial_code + " " + report?.mobile_number;
            report.call_date_time = dateTimeFormat(report?.call_date_time);
            exportData.push(report);
          })
        );
        setCsvData(exportData); //Set the csv data
        setDownloadCsv(true); // Show the CSVDwonload link
        setTimeout(() => {
          csvLink?.current?.link?.click();
          setDownloadCsv(false); // Hide the CSVDwonload link
        }, 100);
      }
    });
  };
  //Prepare the query string for search and filter
  const getQueryParams = () => {
    let queryUrl = `?page=${pageIndex}`;

    queryUrl = searchFormData?.party_type?.value
      ? queryUrl + "&party_type=" + searchFormData?.party_type?.value
      : queryUrl;

    let partyId =
      searchFormData?.party_type?.value === "ra"
        ? searchFormData?.party_id?.ra_id
        : searchFormData?.party_type?.value === "company"
        ? searchFormData?.party_id?.company_id
        : searchFormData?.party_type?.value === "applicant"
        ? searchFormData?.party_id?.applicant_id
        : "";

    queryUrl = partyId ? queryUrl + "&party_id=" + partyId : queryUrl;

    if (searchFormData?.party_type?.value === "other") {
      queryUrl = searchFormData.party_entered_name
        ? queryUrl + "&party_entered_name=" + searchFormData.party_entered_name
        : queryUrl;
    }

    queryUrl = searchFormData?.person_name
      ? queryUrl + "&person_name=" + searchFormData?.person_name
      : queryUrl;

    queryUrl = searchFormData.status_id?.setting_id
      ? queryUrl + "&status_id=" + searchFormData.status_id?.setting_id
      : queryUrl;

    return queryUrl;
  };
  //END : Search and Filter form data

  const leadOptions = [
    { label: "Recruiting agency", value: "ra" },
    { label: "Company", value: "company" },
    { label: "Applicant", value: "applicant" },
    { label: "Other", value: "other" },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <CardTopBoxAdd
                title={"Call Report"}
                link={"/call_report"}
                showAddButton={showAddButton}
              />
              <MDBox>
                <Grid container p={2} spacing={2}>
                  {/* START : Search and filter block */}
                  <Grid
                    container
                    className="searchFilterBlock"
                    p={2}
                    spacing={2}
                    mt={1}
                  >
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.party_type}
                          size="small"
                          name="party_type"
                          options={leadOptions}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["party_type"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["party_type"]: "",
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Party Type"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    {searchFormData.party_type?.value !== "other" && (
                      <Grid item xs={6} md={2}>
                        <MDBox mb={2} className="mdsAutocompleteGrid">
                          <Autocomplete
                            value={searchFormData.party_id}
                            size="small"
                            name="party_id"
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setSearchFormData({
                                  ...searchFormData,
                                  ["party_id"]: newValue,
                                });
                              } else {
                                setSearchFormData({
                                  ...searchFormData,
                                  ["party_id"]: "",
                                });
                              }
                            }}
                            options={
                              searchFormData.party_type?.value === "ra"
                                ? raList
                                : searchFormData.party_type?.value ===
                                  "applicant"
                                ? applicantList
                                : searchFormData.party_type?.value === "company"
                                ? companyList
                                : []
                            }
                            getOptionLabel={(paymentOptions) => {
                              if (searchFormData.party_type?.value === "ra") {
                                return paymentOptions?.ra_name || "";
                              } else if (
                                searchFormData.party_type?.value === "applicant"
                              ) {
                                return paymentOptions?.first_name
                                  ? paymentOptions?.first_name +
                                      " " +
                                      paymentOptions?.last_name
                                  : "";
                              } else if (
                                searchFormData.party_type?.value === "company"
                              ) {
                                return paymentOptions?.name || "";
                              } else {
                                return "";
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Party Name"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) =>
                                  searchSpot(
                                    searchFormData.party_type?.value,
                                    e.target.value
                                  )
                                }
                              />
                            )}
                          />
                        </MDBox>
                      </Grid>
                    )}

                    {searchFormData.party_type?.value === "other" && (
                      <Grid item xs={6} md={2}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            name="party_entered_name"
                            label="Party Name"
                            fullWidth
                            value={searchFormData.party_entered_name}
                            onChange={onChangeSearch}
                          />
                        </MDBox>
                      </Grid>
                    )}

                    <Grid item xs={6} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          name="person_name"
                          type="text"
                          label="Person Name"
                          fullWidth
                          value={searchFormData.person_name}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.status_id}
                          size="small"
                          name="status_id"
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["status_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["status_id"]: "",
                              });
                            }
                          }}
                          options={statusList}
                          getOptionLabel={(statusList) =>
                            statusList.setting_value || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Status"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <MDBox display="flex" alignItems="center">
                        {/* {downloadCsv && (
                          <>
                            <CSVDownload
                              data={csvData}
                              filename={"my-3534564-file.csv"}
                              headers={csvHeaders}
                              target="_self"
                            />
                          </>
                        )} */}
                        <CSVLink
                          className=" d-none "
                          data={csvData}
                          filename={`Call Report ${dateTimeStamp()}`}
                          headers={csvHeaders}
                          ref={csvLink}
                          target="_self"
                        />
                        <MDButton
                          variant="gradient"
                          color="primary"
                          size="small"
                          type="button"
                          className="searchButton"
                          onClick={exportCsv}
                        >
                          <DownloadIcon /> Export CSV
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                  {/* END : Search and filter block */}

                  {!pageResize && (
                    <Grid item xs={12} md={12} lg={12}>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Party Name
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Person Name
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Person Position
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Mobile Number
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Call Date & Time
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={1}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Status
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={1}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Action
                          </MDTypography>
                        </Grid>
                      </MDBox>
                    </Grid>
                  )}

                  {callReportList.map((callReportItem, index) => {
                    let {
                      call_report_id,
                      person_name,
                      person_position,
                      mobile_number,
                      landline_number,
                      email_id,
                      call_date_time,
                      remarks,
                      party_type,
                      party_id,
                      party_name,
                      party_entered_name,
                      status_value,
                      mobile_code_dial_code,
                      landline_code_dial_code,
                    } = callReportItem;
                    return (
                      <>
                        {!pageResize ? (
                          <Grid
                            item
                            key={call_report_id}
                            xs={12}
                            md={12}
                            lg={12}
                          >
                            <MDBox
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              p={1}
                              sx={{
                                border: ({
                                  borders: { borderWidth, borderColor },
                                }) => `${borderWidth[1]} solid ${borderColor}`,
                              }}
                            >
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {party_type === "other"
                                    ? party_entered_name
                                    : party_name}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {person_name}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {person_position}
                                </MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {mobile_code_dial_code + " " + mobile_number}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {dateTimeFormat(call_date_time)}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={1}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {status_value}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={1}>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  color="inherit"
                                  onClick={() =>
                                    handleOpenDialog(callReportItem)
                                  }
                                >
                                  <RemoveRedEye />
                                </IconButton>
                                {showEditButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    onClick={() =>
                                      navigate(`/call_report/${call_report_id}`)
                                    }
                                  >
                                    <Edit />
                                  </IconButton>
                                )}
                                {showDeleteButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                  >
                                    <Delete />
                                  </IconButton>
                                )}
                              </Grid>
                            </MDBox>
                          </Grid>
                        ) : (
                          <MobileList
                            key={call_report_id}
                            callReportItem={callReportItem}
                            index={index + 1}
                            handleOpenDialog={handleOpenDialog}
                            showEditButton={showEditButton}
                            showDeleteButton={showDeleteButton}
                          />
                        )}
                      </>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack spacing={2} mb={2} ml={2}>
                    <Pagination
                      count={pageCount}
                      page={pageIndex}
                      onChange={doPaginationChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ViewMoreRa
        openDialog={openDialog}
        callReportData={callReportData}
        handleCloseDialog={handleCloseDialog}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default CallReportList;
