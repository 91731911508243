// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";
import { useEffect, useRef, useState } from "react";
import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Tooltip,
} from "@mui/material";
import { settingDefault } from "utils/clientFunctions";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MDAvatar from "components/MDAvatar";
import { Delete, Edit, RemoveRedEye, TramSharp } from "@mui/icons-material";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import DownloadIcon from "@mui/icons-material/Download";
import { useAuth } from "utils/auth";
import pxToRem from "assets/theme/functions/pxToRem";
import MobileList from "../components/mobileList";
import { dateFormat } from "utils/clientFunctions";
import { CardTopBoxAdd } from "layouts/commonComponents/cardTopBoxAdd";
import { dateTimeFormat } from "utils/clientFunctions";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import PrintInvoice from "../components/pdf";

import { firstLetterCapital } from "utils/clientFunctions";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CSVDownload, CSVLink } from "react-csv";
import { dateTimeStamp } from "utils/clientFunctions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function InvoiceList() {
  const csvLink = useRef();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageResize, setPageResize] = useState(
    window.innerWidth < 992 ? true : false
  );
  const navigate = useNavigate();

  //Start : For permission
  const dispatch = useDispatch();
  var permissions = useSelector(selectPermissions);
  if (
    !(
      permissions?.user_id === 1 || permissions?.permission?.includes("bokView")
    )
  ) {
    navigate("/dashboard");
  }
  let showAddButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("bokCreate")
      ? true
      : false;
  let showEditButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("bokEdit")
      ? true
      : false;
  let showDeleteButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("bokDelete")
      ? true
      : false;
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const [invoiceList, setInvoiceList] = useState([]);
  const [usersList, setUserList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [applicantList, setApplicantList] = useState([]);
  const [raList, setRaList] = useState([]);
  //View More RA data
  const [invoiceData, setInvoiceData] = useState({});
  const [print, setPrint] = useState(false);
  const [download, setDownload] = useState(false);
  const { logout } = useAuth();

  const initialLoadData = async () => {
    let queryUrl = getQueryParams();
    const url = `invoice/list${queryUrl}`;
    const data = await fetchData(url);
    if (
      data?.name === "JsonWebTokenError" ||
      data?.name === "TokenExpiredError" ||
      data?.message === "You are not authorized"
    ) {
      logout();
    }
    if (data && data.data) {
      setPageCount(Math.ceil(data.count / 10));
      setInvoiceList(data.data || []);
    }
  };

  //Search & Filter initial data
  const [searchFormData, setSearchFormData] = useState({
    invoice_date: "",
    invoice_number: "",
    bill_to_vendor_type: "",
    bill_to_vendor_type_id: "",
    total_invoice_amount: "",
  });

  useEffect(() => {
    initialLoadData();
  }, [
    pageIndex,
    searchFormData.invoice_date,
    searchFormData.invoice_number,
    searchFormData.bill_to_vendor_type,
    searchFormData.bill_to_vendor_type_id,
    searchFormData.total_invoice_amount,
  ]);

  const getFetchData = async (url) => {
    return await fetchData(url);
  };
  const searchSpot = (value, key) => {
    liveSearchData({
      countryId: null,
      value,
      key,
      setRaList,
      setCompanyList,
      setApplicantList,
      setUserList,
    });
  };
  useEffect(() => {
    //Get company list
    const companyApiUrl = `company/list?page=1`;
    getFetchData(companyApiUrl).then((res) => {
      setCompanyList(res.data);
    });

    const applicantUrl = `applicant/list?page=1`;
    getFetchData(applicantUrl).then((res) => {
      setApplicantList(res.data);
    });

    const raUrl = `recruiting_agency/list?page=1`;
    getFetchData(raUrl).then((res) => {
      setRaList(res.data);
    });
  }, []);

  const vendorOptions = [
    { label: "Recruiting agency", value: "ra" },
    { label: "Company", value: "company" },
    { label: "Applicant", value: "applicant" },
  ];
  useEffect(() => {
    initialLoadData();
  }, [pageIndex]);

  const doPaginationChange = (event, value) => {
    setPageIndex(value);
  };

  //Dialog popup
  const [openPrintdialog, setOpenPrintdialog] = useState(false);
  const handlePrintDownloadInvoice = (invoiceData, print, download) => {
    setInvoiceData(invoiceData); //Update the invoice props

    setOpenPrintdialog(true); //Open the print dialog

    setPrint(print); // If print is true then print the invoice
    setDownload(download); // If download is true then download the invoice

    // Close the print dialog & reset the  print & invoice
    setTimeout(
      () => {
        setOpenPrintdialog(false);
        setPrint(false);
        setDownload(false);
      },
      download ? 1000 : 100
    );
  };

  window.addEventListener("resize", () => {
    if (window.innerWidth < 992) {
      setPageResize(true);
    } else {
      setPageResize(false);
    }
  });

  //START : Search and Filter form data
  //Handle onchange search and filter
  const onChangeSearch = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setSearchFormData({ ...searchFormData, [name]: val });
  };
  const [csvData, setCsvData] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);

  //   Invoice Date
  // Invoice Number
  // Due Date
  // Vendor Name
  // Sub Total Amount
  // Discount Amount
  // Tax Amount
  // Total Amount
  // Status
  let csvHeaders = [
    { label: "Invoice Date", key: "invoice_date" },
    { label: "Invoice Number", key: "invoice_number" },
    { label: "Due Date", key: "due_date" },
    { label: "Vendor Name", key: "bill_name" },
    { label: "Sub Total Amount", key: "sub_total_amount" },
    { label: "Discount Amount", key: "discount_amount" },
    { label: "Tax Amount", key: "tax_amount" },
    { label: "Total Amount", key: "total_invoice_amount" },
    { label: "Status", key: "status" },
  ];
  //Prepare the csv data for export
  const exportCsv = async () => {
    let queryUrl = getQueryParams();
    queryUrl = queryUrl + `&download=1`;
    getFetchData(`invoice/list${queryUrl}`).then(async (res) => {
      if (res?.data) {
        let exportData = [];
        await Promise.all(
          res?.data.map((report) => {
            report.invoice_date = report?.invoice_date
              ? dateFormat(report?.invoice_date)
              : "-";
            report.due_date = report?.due_date
              ? dateFormat(report?.due_date)
              : "-";
            report.sub_total_amount =
              report?.br_currency_info?.currency_code +
              " " +
              report?.sub_total_amount * 1;

            report.discount_amount =
              report?.br_currency_info?.currency_code +
              " " +
              report?.discount_amount * 1;

            report.tax_amount =
              report?.br_currency_info?.currency_code +
              " " +
              report?.tax_amount * 1;

            report.total_invoice_amount =
              report?.br_currency_info?.currency_code +
              " " +
              report?.total_invoice_amount * 1;
            report.status = report?.status_info?.setting_value;
            exportData.push(report);
          })
        );
        setCsvData(exportData); //Set the csv data
        setDownloadCsv(true); // Show the CSVDwonload link
        setTimeout(() => {
          csvLink?.current?.link?.click();
          setDownloadCsv(false); // Hide the CSVDwonload link
        }, 100);
      }
    });
  };
  //Prepare the query string for search and filter
  const getQueryParams = () => {
    let queryUrl = `?page=${pageIndex}`;

    queryUrl = searchFormData?.invoice_date
      ? queryUrl + "&invoice_date=" + searchFormData?.invoice_date
      : queryUrl;

    queryUrl = searchFormData?.invoice_number
      ? queryUrl + "&invoice_number=" + searchFormData?.invoice_number
      : queryUrl;

    let vendorType = searchFormData?.bill_to_vendor_type?.value;
    let vendorTypeId =
      vendorType === "company"
        ? searchFormData?.bill_to_vendor_type_id?.company_id
        : vendorType === "ra"
        ? searchFormData?.bill_to_vendor_type_id?.ra_id
        : vendorType === "applicant"
        ? searchFormData?.bill_to_vendor_type_id?.applicant_id
        : "";

    queryUrl = vendorType
      ? queryUrl + "&bill_to_vendor_type=" + vendorType
      : queryUrl;
    queryUrl = vendorTypeId
      ? queryUrl + "&bill_to_vendor_type_id=" + vendorTypeId
      : queryUrl;

    queryUrl = searchFormData?.total_invoice_amount
      ? queryUrl +
        "&total_invoice_amount=" +
        searchFormData?.total_invoice_amount
      : queryUrl;

    return queryUrl;
  };
  //END : Search and Filter form data

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <CardTopBoxAdd
                title={"Invoices"}
                link={"/invoice"}
                showAddButton={showAddButton}
              />
              <MDBox>
                <Grid container p={2} spacing={2}>
                  {/* START : Search and filter block */}
                  <Grid
                    container
                    className="searchFilterBlock"
                    p={2}
                    spacing={2}
                    mt={1}
                  >
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          name="invoice_number"
                          label="Invoice Number"
                          fullWidth
                          value={searchFormData.invoice_number}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={6} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          type="date"
                          name="invoice_date"
                          label="Invoice Date"
                          fullWidth
                          value={searchFormData.invoice_date}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.bill_to_vendor_type}
                          size="small"
                          defaultValue={vendorOptions[0]}
                          name="bill_to_vendor_type"
                          options={vendorOptions}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["bill_to_vendor_type"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["bill_to_vendor_type"]: "",
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Bill To"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.bill_to_vendor_type_id}
                          size="small"
                          name="bill_to_vendor_type_id"
                          options={
                            searchFormData.bill_to_vendor_type?.value === "ra"
                              ? raList
                              : searchFormData.bill_to_vendor_type?.value ===
                                "applicant"
                              ? applicantList
                              : searchFormData.bill_to_vendor_type?.value ===
                                "company"
                              ? companyList
                              : []
                          }
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["bill_to_vendor_type_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["bill_to_vendor_type_id"]: "",
                              });
                            }
                          }}
                          getOptionLabel={(vendorTypeOptions) => {
                            if (
                              searchFormData.bill_to_vendor_type?.value === "ra"
                            ) {
                              return vendorTypeOptions?.ra_name || "";
                            } else if (
                              searchFormData.bill_to_vendor_type?.value ===
                              "applicant"
                            ) {
                              return vendorTypeOptions?.first_name
                                ? vendorTypeOptions?.first_name +
                                    " " +
                                    vendorTypeOptions?.last_name
                                : "";
                            } else if (
                              searchFormData.bill_to_vendor_type?.value ===
                              "company"
                            ) {
                              return vendorTypeOptions?.name
                                ? vendorTypeOptions?.name
                                : "";
                            } else {
                              return "";
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Vendor Name"
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) =>
                                searchSpot(
                                  searchFormData.bill_to_vendor_type?.value,
                                  e.target.value
                                )
                              }
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          type="number"
                          name="total_invoice_amount"
                          label="Invoice Amount"
                          fullWidth
                          value={searchFormData.total_invoice_amount}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox display="flex" alignItems="center">
                        {/* {downloadCsv && (
                          <>
                            <CSVDownload
                              data={csvData}
                              filename={"my-3534564-file.csv"}
                              headers={csvHeaders}
                              target="_self"
                            />
                          </>
                        )} */}
                        <CSVLink
                          className=" d-none "
                          data={csvData}
                          filename={`Invoices ${dateTimeStamp()}`}
                          headers={csvHeaders}
                          ref={csvLink}
                          target="_self"
                        />
                        <MDButton
                          variant="gradient"
                          color="primary"
                          size="small"
                          type="button"
                          className="searchButton"
                          onClick={exportCsv}
                        >
                          <DownloadIcon /> Export CSV
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                  {/* END : Search and filter block */}

                  {!pageResize && (
                    <Grid item xs={12} md={12} lg={12}>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        {/* Invoice Date | Inv number | Vendor Name | Total Amount |
                        Due Date | Status | Action */}
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Invoice Date
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Invoice Number
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Vendor Name
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Total Amount
                          </MDTypography>
                        </Grid>
                        {/* <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Due Date
                          </MDTypography>
                        </Grid> */}
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Status
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Action
                          </MDTypography>
                        </Grid>
                      </MDBox>
                    </Grid>
                  )}
                  {invoiceList.map((invoiceItem, index) => {
                    let {
                      invoice_id,
                      invoice_date,
                      invoice_number,
                      bill_name,
                      total_invoice_amount,
                      due_date,
                      status_info,
                      br_currency_info,
                    } = invoiceItem;
                    return (
                      <>
                        {!pageResize ? (
                          <Grid item key={invoice_id} xs={12} md={12} lg={12}>
                            <MDBox
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              p={1}
                              sx={{
                                border: ({
                                  borders: { borderWidth, borderColor },
                                }) => `${borderWidth[1]} solid ${borderColor}`,
                              }}
                            >
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {dateFormat(invoice_date)}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {invoice_number}
                                </MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {bill_name}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {br_currency_info?.currency_code +
                                    " " +
                                    total_invoice_amount * 1}
                                </MDTypography>
                              </Grid>
                              {/* <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {dateFormat(due_date)}
                                </MDTypography>
                              </Grid> */}
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {status_info?.setting_value}
                                </MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} lg={2}>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  color="inherit"
                                  onClick={() =>
                                    navigate(`/invoice/view/${invoice_id}`)
                                  }
                                >
                                  <RemoveRedEye />
                                </IconButton>
                                {showEditButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    onClick={() =>
                                      navigate(`/invoice/${invoice_id}`)
                                    }
                                  >
                                    <Edit />
                                  </IconButton>
                                )}
                                <IconButton
                                  size="small"
                                  disableRipple
                                  color="inherit"
                                  onClick={() =>
                                    handlePrintDownloadInvoice(
                                      invoiceItem,
                                      false,
                                      true
                                    )
                                  }
                                >
                                  <DownloadIcon />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  color="inherit"
                                  onClick={() =>
                                    handlePrintDownloadInvoice(
                                      invoiceItem,
                                      true,
                                      false
                                    )
                                  }
                                >
                                  <LocalPrintshopIcon />
                                </IconButton>

                                {showDeleteButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                  >
                                    <Delete />
                                  </IconButton>
                                )}
                              </Grid>
                            </MDBox>
                          </Grid>
                        ) : (
                          <MobileList
                            key={invoice_id}
                            invoiceItem={invoiceItem}
                            index={index + 1}
                            handlePrintDownloadInvoice={
                              handlePrintDownloadInvoice
                            }
                            showEditButton={showEditButton}
                            showDeleteButton={showDeleteButton}
                          />
                        )}
                      </>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack spacing={2} mb={2} ml={2}>
                    <Pagination
                      count={pageCount}
                      page={pageIndex}
                      onChange={doPaginationChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {openPrintdialog && (
        <PrintInvoice data={invoiceData} print={print} download={download} />
      )}

      <Footer />
    </DashboardLayout>
  );
}

export default InvoiceList;
