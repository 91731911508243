// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { fetchData } from "utils/clientFunctions";
import { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import { useAuth } from "utils/auth";
import pxToRem from "assets/theme/functions/pxToRem";
import MDInput from "components/MDInput";
import {
  dateTimeFormat,
  dateFormat,
  convertTo12HrsFormat,
} from "utils/clientFunctions";
import { CardTopBoxAdd } from "layouts/commonComponents/cardTopBoxAdd";
import MeetingRes from "../components/meetingRes";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CSVDownload, CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import MDButton from "components/MDButton";
import { firstLetterCapital } from "utils/clientFunctions";
import { dateTimeStamp } from "utils/clientFunctions";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function MeetingsList() {
  const csvLink = useRef();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageResize, setPageResize] = useState(
    window.innerWidth < 992 ? true : false
  );
  const navigate = useNavigate();

  //Start : For permission
  const dispatch = useDispatch();
  var permissions = useSelector(selectPermissions);
  if (
    !(
      permissions?.user_id === 1 || permissions?.permission?.includes("metView")
    )
  ) {
    navigate("/dashboard");
  }
  let showAddButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("metCreate")
      ? true
      : false;
  let showEditButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("metEdit")
      ? true
      : false;
  let showDeleteButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("metDelete")
      ? true
      : false;
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const [List, setList] = useState([]);
  const [raData, setRaData] = useState({});
  const [userList, setUserList] = useState([]);
  const [reasonList, setreasonList] = useState([]);
  const [statusList, setstatusList] = useState([]);
  const { logout } = useAuth();

  const initialLoadData = async () => {
    let queryUrl = getQueryParams();
    const url = `meeting/list${queryUrl}`;
    const data = await fetchData(url);
    if (
      data?.name === "JsonWebTokenError" ||
      data?.name === "TokenExpiredError" ||
      data?.message === "You are not authorized"
    ) {
      logout();
    }
    if (data && data.data) {
      let meetingCount = Math.ceil(data.count / 10);
      setPageCount(meetingCount);
      setList(data.data || []);
    }
  };
  useEffect(() => {
    const userUrl = `user/list?page=1`;
    getFetchData(userUrl).then((res) => {
      setUserList(res.data);
    });

    const reasonUrl = `setting/type/12`;
    getFetchData(reasonUrl).then((res) => {
      setreasonList(res.data);
    });

    const statusUrl = `setting/type/8`;
    getFetchData(statusUrl).then((res) => {
      setstatusList(res.data);
    });
  }, []);
  const searchSpot = (value, key) => {
    liveSearchData({
      countryId: null,
      value,
      key,
      setUserList,
    });
  };
  const getFetchData = async (settingUrl) => {
    return await fetchData(settingUrl);
  };
  //Search & Filter initial data
  const [searchFormData, setSearchFormData] = useState({
    user_id: "",
    reason_id: "",
    meeting_with: "",
    meeting_date: "",
    status_id: "",
  });
  useEffect(() => {
    initialLoadData();
  }, [
    pageIndex,
    searchFormData.user_id,
    searchFormData.reason_id,
    searchFormData.meeting_with,
    searchFormData.meeting_date,
    searchFormData.status_id,
  ]);

  const doPaginationChange = (event, value) => {
    setPageIndex(value);
  };

  //Dialog popup
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = (raInfo) => {
    setRaData(raInfo);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setRaData({});
    setOpenDialog(false);
  };

  window.addEventListener("resize", () => {
    if (window.innerWidth < 992) {
      setPageResize(true);
    } else {
      setPageResize(false);
    }
  });

  const displayTypoInfo = (label, value) => {
    return (
      <>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="medium">
            {label}
          </MDTypography>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="regular">
            {value}
          </MDTypography>
        </Grid>
      </>
    );
  };

  const ViewMoreRa = ({ openDialog, raData, handleCloseDialog }) => {
    const {
      meeting_id,
      vendor_type,
      meeting_with,
      meeting_mode,
      meeting_date,
      start_time,
      end_time,
      remarks,
      reason_info,
      status_info,
      user_info,
      attendees_list,
    } = raData;

    return (
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        fullScreen={window.innerWidth < 768 ? true : false}
      >
        <DialogTitle sx={{ m: 0 }} id="customized-dialog-title">
          {"Meeting Details"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container px={2} spacing={1}>
            <ViewTypo
              title={"Organised By"}
              res={user_info?.first_name + " " + user_info?.last_name}
            />
            <ViewTypo title={"Agenda"} res={reason_info?.setting_value} />
            <ViewTypo
              title={"Vendor Type"}
              res={
                vendor_type === "ra"
                  ? "Recruiting Agency"
                  : vendor_type === "company"
                  ? "Company"
                  : "Applicant"
              }
            />
            <ViewTypo title={"Meeting With"} res={meeting_with} />
            <ViewTypo title={"Meeting Mode"} res={meeting_mode} />
            <ViewTypo title={"Meeting Date"} res={dateFormat(meeting_date)} />
            <ViewTypo
              title={"Start & End Time"}
              res={
                convertTo12HrsFormat(start_time) +
                " - " +
                convertTo12HrsFormat(end_time)
              }
            />
            <ViewTypo title={"Status"} res={status_info?.setting_value} />
            <ViewTypo title={"Remarks  "} res={remarks} />

            <Grid item xs={12} md={12} lg={12}>
              <MDTypography variant="h6" fontWeight="medium">
                {attendees_list?.length ? (
                  <MDBox
                    py={1}
                    px={1}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      List of Attendees
                    </MDTypography>
                  </MDBox>
                ) : (
                  ""
                )}
              </MDTypography>
            </Grid>
            {attendees_list?.map((puser, i) => {
              const { attendee_info: user_info } = puser;
              return (
                <>
                  <Grid item xs={12} md={12} lg={12}>
                    <MDTypography variant="h6" fontWeight="medium">
                      {i + 1}.
                    </MDTypography>
                  </Grid>
                  {displayTypoInfo(
                    "Name",
                    user_info?.first_name + " " + user_info?.last_name
                  )}
                  {displayTypoInfo("Mobile Number", user_info?.mobile_number)}
                  {displayTypoInfo("E-mail", user_info?.email_id)}
                </>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    );
  };

  const HeadTypo = ({ title, lg }) => {
    return (
      <Grid item xs={4} md={4} lg={lg}>
        <MDTypography fontSize={pxToRem(14)} fontWeight="medium">
          {title}
        </MDTypography>
      </Grid>
    );
  };

  const BodyTypo = ({ title, lg }) => {
    return (
      <Grid item xs={4} md={4} lg={lg}>
        <MDTypography fontSize={pxToRem(14)} fontWeight="regular">
          {title}
        </MDTypography>
      </Grid>
    );
  };

  const ViewTypo = ({ title, res }) => {
    return (
      <>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="medium">
            {title}
          </MDTypography>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="regular">
            {res}
          </MDTypography>
        </Grid>
      </>
    );
  };
  //START : Search and Filter form data
  //Handle onchange search and filter
  const onChangeSearch = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setSearchFormData({ ...searchFormData, [name]: val });
  };
  const [csvData, setCsvData] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);
  let csvHeaders = [
    { label: "Organised By", key: "organised_by" },
    { label: "Agenda", key: "agenda" },
    { label: "Vendor Type", key: "vendor_type" },
    { label: "Meeting With", key: "meeting_with" },
    { label: "Meeting Date", key: "meeting_date" },
    { label: "Meeting Mode", key: "meeting_mode" },
    { label: "Start & End Time", key: "start_end_time" },
    { label: "Status", key: "status" },
  ];
  //Prepare the csv data for export
  const exportCsv = async () => {
    let queryUrl = getQueryParams();
    queryUrl = queryUrl + `&download=1`;
    getFetchData(`meeting/list${queryUrl}`).then(async (res) => {
      if (res?.data) {
        let exportData = [];
        await Promise.all(
          res?.data.map((report) => {
            report.organised_by =
              report?.user_info?.first_name +
              " " +
              report?.user_info?.last_name;
            report.agenda = report?.reason_info?.setting_value;

            report.vendor_type =
              report?.vendor_type === "ra"
                ? "Recruiting Agency"
                : firstLetterCapital(report?.vendor_type);

            report.meeting_date = report?.meeting_date
              ? dateFormat(report?.meeting_date)
              : "-";
            report.start_end_time =
              convertTo12HrsFormat(report?.start_time) +
              "-" +
              convertTo12HrsFormat(report?.end_time);
            report.status = report?.status_info?.setting_value;
            exportData.push(report);
          })
        );
        setCsvData(exportData); //Set the csv data
        setDownloadCsv(true); // Show the CSVDwonload link
        setTimeout(() => {
          csvLink?.current?.link?.click();
          setDownloadCsv(false); // Hide the CSVDwonload link
        }, 100);
      }
    });
  };
  //Prepare the query string for search and filter
  const getQueryParams = () => {
    let queryUrl = `?page=${pageIndex}`;

    queryUrl = searchFormData?.user_id?.user_id
      ? queryUrl + "&user_id=" + searchFormData?.user_id?.user_id
      : queryUrl;

    queryUrl = searchFormData?.meeting_with
      ? queryUrl + "&meeting_with=" + searchFormData?.meeting_with
      : queryUrl;

    queryUrl = searchFormData?.meeting_date
      ? queryUrl + "&meeting_date=" + searchFormData?.meeting_date
      : queryUrl;

    queryUrl = searchFormData?.reason_id?.setting_id
      ? queryUrl + "&reason_id=" + searchFormData?.reason_id?.setting_id
      : queryUrl;

    queryUrl = searchFormData.status_id?.setting_id
      ? queryUrl + "&status_id=" + searchFormData.status_id?.setting_id
      : queryUrl;

    return queryUrl;
  };
  //END : Search and Filter form data
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <CardTopBoxAdd
                title={"Meetings"}
                link={"/meetingdata"}
                showAddButton={showAddButton}
              />
              <MDBox>
                <Grid container p={2} spacing={2}>
                  {/* START : Search and filter block */}
                  <Grid
                    container
                    className="searchFilterBlock"
                    p={2}
                    spacing={2}
                    mt={1}
                  >
                    <Grid item xs={12} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.user_id}
                          size="small"
                          name="user_id"
                          options={userList}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["user_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["user_id"]: "",
                              });
                            }
                          }}
                          getOptionLabel={(userList) =>
                            (userList &&
                              userList.first_name + " " + userList.last_name) ||
                            ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Organised By *"
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) =>
                                searchSpot("user", e.target.value)
                              }
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.reason_id}
                          size="small"
                          name="reason_id"
                          options={reasonList}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["reason_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["reason_id"]: "",
                              });
                            }
                          }}
                          getOptionLabel={(reasonList) =>
                            reasonList.setting_value || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Agenda *"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          name="meeting_with"
                          type="text"
                          label="Meeting with *"
                          fullWidth
                          value={searchFormData.meeting_with}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          name="meeting_date"
                          type="date"
                          label="Meeting Date *"
                          fullWidth
                          value={searchFormData.meeting_date}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.status_id}
                          size="small"
                          name="status_id"
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["status_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["status_id"]: "",
                              });
                            }
                          }}
                          options={statusList}
                          getOptionLabel={(statusList) =>
                            statusList.setting_value || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Status"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox display="flex" alignItems="center">
                        {/* {downloadCsv && (
                          <>
                            <CSVDownload
                              data={csvData}
                              filename={"my-3534564-file.csv"}
                              headers={csvHeaders}
                              target="_self"
                            />
                          </>
                        )} */}
                        <CSVLink
                          className=" d-none "
                          data={csvData}
                          filename={`Meetings ${dateTimeStamp()}`}
                          headers={csvHeaders}
                          ref={csvLink}
                          target="_self"
                        />
                        <MDButton
                          variant="gradient"
                          color="primary"
                          size="small"
                          type="button"
                          className="searchButton"
                          onClick={exportCsv}
                        >
                          <DownloadIcon /> Export CSV
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                  {/* END : Search and filter block */}

                  {!pageResize && (
                    <Grid item xs={12} md={12} lg={12}>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <HeadTypo title="Organised By" lg={2} />
                        <HeadTypo title="Agenda" lg={2} />
                        <HeadTypo title="Meeting With" lg={3} />
                        <HeadTypo title="Meeting Date" lg={2} />
                        <HeadTypo title="Start - End Time" lg={3} />
                        <HeadTypo title="Status" lg={2} />
                        <HeadTypo title="Action" lg={2} />
                      </MDBox>
                    </Grid>
                  )}
                  {List.map((raItem, index) => {
                    let {
                      meeting_id,
                      user_info,
                      meeting_with,
                      remarks,
                      reason_info,
                      meeting_date,
                      status_info,
                      start_time,
                      end_time,
                    } = raItem;
                    return (
                      <>
                        {!pageResize ? (
                          <Grid item key={meeting_id} xs={12} md={12} lg={12}>
                            <MDBox
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              p={1}
                              sx={{
                                border: ({
                                  borders: { borderWidth, borderColor },
                                }) => `${borderWidth[1]} solid ${borderColor}`,
                              }}
                            >
                              <BodyTypo
                                title={
                                  user_info?.first_name +
                                  " " +
                                  user_info?.last_name
                                }
                                lg={2}
                              />
                              <BodyTypo
                                title={reason_info?.setting_value}
                                lg={2}
                              />
                              <BodyTypo title={meeting_with} lg={3} />
                              <BodyTypo
                                title={dateFormat(meeting_date)}
                                lg={2}
                              />
                              <BodyTypo
                                title={
                                  convertTo12HrsFormat(start_time) +
                                  "-" +
                                  convertTo12HrsFormat(end_time)
                                }
                                lg={3}
                              />
                              <BodyTypo
                                title={status_info?.setting_value}
                                lg={2}
                              />
                              <Grid item xs={4} md={4} lg={2}>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  color="inherit"
                                  onClick={() => handleOpenDialog(raItem)}
                                >
                                  <RemoveRedEye />
                                </IconButton>
                                {showEditButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    onClick={() =>
                                      navigate(`/meetingdata/${meeting_id}`)
                                    }
                                  >
                                    <Edit />
                                  </IconButton>
                                )}
                                {showDeleteButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                  >
                                    <Delete />
                                  </IconButton>
                                )}
                              </Grid>
                            </MDBox>
                          </Grid>
                        ) : (
                          <MeetingRes
                            key={meeting_id}
                            raItem={raItem}
                            index={index + 1}
                            handleOpenDialog={handleOpenDialog}
                            showEditButton={showEditButton}
                            showDeleteButton={showDeleteButton}
                          />
                        )}
                      </>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack spacing={2} mb={2} ml={2}>
                    <Pagination
                      count={pageCount}
                      page={pageIndex}
                      onChange={doPaginationChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ViewMoreRa
        openDialog={openDialog}
        raData={raData}
        handleCloseDialog={handleCloseDialog}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default MeetingsList;
