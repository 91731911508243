// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import useSWR from "swr";
import { useEffect, useState } from "react";
//import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import { DialogContent } from "@mui/material";
//import { settingDefault } from "utils/clientFunctions";
import { Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";

import { postData, doUpload } from "utils/clientFunctions";
import { useToasts } from "react-toast-notifications";

import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../../src/assets/css/Custom.css";
import { useFormik } from "formik";

import { createLeadSchema } from "../components/schemas";
import { CardTopBoxBack } from "layouts/commonComponents/cardTopBoxBack";
import { updateData } from "utils/clientFunctions";
import { removeDuplicates } from "utils/clientFunctions";
import { CardLayoutAdd } from "layouts/commonComponents/cardLayoutAdd";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
function LeadData() {
  let { id } = useParams();
  const [industries, setIndustries] = useState([]);
  const [companySizes, setCompanySizes] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [usersList, setUserList] = useState([]);
  const [statusList, setstatusList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [applicantList, setApplicantList] = useState([]);
  const [raList, setRaList] = useState([]);
  const [name, setName] = useState("");

  const { addToast } = useToasts();
  const navigate = useNavigate();

  //Start : For permission
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);
  if (id) {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("ledEdit")
      )
    ) {
      navigate("/lead");
    }
  } else {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("ledCreate")
      )
    ) {
      navigate("/lead");
    }
  }
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const leadOptions = [
    { label: "Recruiting agency", value: "ra" },
    { label: "Company", value: "company" },
    { label: "Applicant", value: "applicant" },
  ];

  const priorityOptions = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ];

  const [formData, setFormData] = useState({
    lead_type: { label: "Recruiting agency", value: "ra" }, //Default keep RA
    lead_type_name: "",
    country_id: "",
    industry_id: "",
    priority: "",
    assigned_user_id: "",
    start_date: "",
    end_date: "",
    status_id: "",
    remarks: "",
    //If lead_type is company
    size_of_company: "",
    website: "",
    contact_code_id: "",
    contact_number: "",
    email_id: "",
    //If lead_type is applicant
    position_id: "",
  });

  const searchSpot = (value, key) => {
    liveSearchData({
      countryId: null,
      value,
      key,
      setRaList,
      setCompanyList,
      setApplicantList,
      setUserList,
    });
  };

  const getFetchData = async (settingUrl) => {
    return await fetchData(settingUrl);
  };

  //Get country info
  useEffect(() => {
    if (id) {
      const updateUrl = `leads/${id}`;
      getFetchData(updateUrl).then((res) => {
        let {
          remarks,
          end_date,
          start_date,
          country_info,
          status_info,
          industry_info,
          assigned_user_info,
          lead_type,
          priority,
          company_size,
          contact_code_info,
          position_info,
          email_id,
          contact_number,
          lead_type_name,
          website,
        } = res.data;

        let updatedLeadType = leadOptions?.filter(
          (type) => type.value === lead_type
        );

        let newPriority = {
          label: priority,
          value: priority,
        };
        setFormData({
          lead_type: updatedLeadType && updatedLeadType[0],
          lead_type_name,
          country_id: country_info,
          industry_id: industry_info,
          priority: newPriority,
          assigned_user_id: assigned_user_info,
          start_date: start_date,
          end_date: end_date,
          status_id: status_info,
          remarks: remarks,
          size_of_company: company_size,
          website,
          contact_code_id: contact_code_info,
          contact_number,
          email_id,
          position_id: position_info,
        });
      });
    }
    const countryUrl = `country/list`;
    getFetchData(countryUrl).then((res) => {
      let countryList = res.data;
      setCountryList(countryList);
    });

    const industryUrl = `setting/type/2`;
    getFetchData(industryUrl).then((res) => {
      let industryList = res.data;
      setIndustries(industryList);
    });

    const userUrl = `user/list?page=1`;
    getFetchData(userUrl).then((res) => {
      let userList = res.data;
      setUserList(userList);
    });

    const statusUrl = `setting/type/8`;
    getFetchData(statusUrl).then((res) => {
      let statusList = res.data;
      setstatusList(statusList);
    });

    //Get company list
    const companyApiUrl = `company/list?page=1`;
    getFetchData(companyApiUrl).then((res) => {
      let companyDataList = res.data;
      setCompanyList(companyDataList);
    });

    const applicantUrl = `applicant/list?page=1`;
    getFetchData(applicantUrl).then((res) => {
      let applicantList = res.data;
      setApplicantList(applicantList);
    });

    const raUrl = `recruiting_agency/list?page=1`;
    getFetchData(raUrl).then((res) => {
      let raList = res.data;
      setRaList(raList);
    });

    //Get size of the company
    const companySizeUrl = `setting/type/5`;
    getFetchData(companySizeUrl).then((res) => {
      let companySizeList = res.data;
      setCompanySizes(companySizeList);
    });

    //Get position list
    const positionUrl = `setting/type/4`;
    getFetchData(positionUrl).then((res) => {
      let positionList = res.data;
      setPositionList(positionList);
    });
  }, []);

  const handleFormSubmit = async (data, resetForm) => {
    //Collect final Data
    let submitData = {};
    submitData = data;

    //Update below fields to send only ids
    let leadType = data.lead_type?.value;

    // let leadTypeId =
    //   leadType === "company"
    //     ? data.lead_type_id?.company_id
    //     : leadType === "ra"
    //     ? data.lead_type_id?.ra_id
    //     : leadType === "applicant"
    //     ? data.lead_type_id?.applicant_id
    //     : "";

    submitData.lead_type = leadType;
    submitData.lead_type_name = data.lead_type_name;
    submitData.country_id = data.country_id?.country_id;
    submitData.industry_id = data.industry_id?.setting_id;
    submitData.priority = data.priority?.value;
    submitData.assigned_user_id = data.assigned_user_id?.user_id;
    submitData.status_id = data.status_id?.setting_id;

    submitData.size_of_company = data.size_of_company?.setting_id;
    submitData.contact_code_id = data.contact_code_id?.country_id;

    submitData.position_id = data.position_id?.setting_id;

    const url = `leads`;
    const response = id
      ? await updateData(`leads/${id}`, submitData)
      : await postData(url, submitData);
    if (!response.status) {
      if (response.message) {
        addToast(response.message, { appearance: "error", autoDismiss: true });
      }
      if (response.errors) {
        let apiErrors = response.errors;
        for (let i = 0; i < apiErrors.length; i++) {
          let errorMessage = apiErrors[i].message;
          errorMessage = errorMessage.replace(/['"]+/g, "").replace(/_/g, " ");
          errorMessage =
            errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
          addToast(errorMessage, { appearance: "error", autoDismiss: true });
        }
      }
    } else {
      addToast("Saved Successfully", {
        appearance: "success",
        autoDismiss: true,
      });
      navigate(-1);
    }
  };

  const onChange = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setFormData({ ...formData, [name]: val });
  };

  const ErrorDisp = (name) => {
    return (
      errors[name] &&
      touched[name] && ( // Use `errors[name]` instead of `errors.name`
        <p className="formError">{errors[name]}</p>
      )
    );
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema: createLeadSchema,
    onSubmit: (values, { resetForm }) => {
      handleFormSubmit(values, resetForm);
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Formik
          initialValues={formData}
          enableReinitialze={true}
          onSubmit={handleSubmit}
        >
          <Form>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <CardTopBoxBack title={id ? "Update Lead" : "New Lead"} />
                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.lead_type}
                                  size="small"
                                  defaultValue={leadOptions[0]}
                                  name="lead_type"
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      setFieldValue("lead_type", newValue);
                                    } else {
                                      setFieldValue("lead_type", "");
                                    }
                                  }}
                                  options={leadOptions}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Lead Type"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("lead_type")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="text"
                                  name="lead_type_name"
                                  label="Lead Name"
                                  fullWidth
                                  value={values.lead_type_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("lead_type_name")}
                              </MDBox>
                            </Grid>
                            {/* <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.lead_type_id}
                                  size="small"
                                  name="lead_type_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("lead_type_id", newValue)
                                      : setFieldValue("lead_type_id", "")
                                  }
                                  options={
                                    values.lead_type?.value === "ra"
                                      ? raList
                                      : values.lead_type?.value === "applicant"
                                      ? applicantList
                                      : values.lead_type?.value === "company"
                                      ? companyList
                                      : raList
                                  }
                                  getOptionLabel={(paymentOptions) => {
                                    if (values.lead_type?.value === "ra") {
                                      return paymentOptions?.ra_name || "";
                                    } else if (
                                      values.lead_type?.value === "applicant"
                                    ) {
                                      return paymentOptions?.first_name
                                        ? paymentOptions?.first_name +
                                            " " +
                                            paymentOptions?.last_name
                                        : "";
                                    } else if (
                                      values.lead_type?.value === "company"
                                    ) {
                                      return paymentOptions?.name
                                        ? paymentOptions?.name
                                        : "";
                                    } else {
                                      return (
                                        paymentOptions?.ra_name || "" || ""
                                      );
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Lead Name"
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(e) =>
                                        searchSpot(
                                          values.lead_type?.value,
                                          e.target.value
                                        )
                                      }
                                    />
                                  )}
                                />
                                {ErrorDisp("lead_type_id")}
                              </MDBox>
                            </Grid> */}
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.country_id}
                                  size="small"
                                  name="country_id"
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      setFieldValue("country_id", newValue);
                                    } else {
                                      setFieldValue("country_id", "");
                                    }
                                  }}
                                  options={countryList}
                                  getOptionLabel={(countryList) =>
                                    countryList.name || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Country"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("country_id")}
                              </MDBox>
                            </Grid>

                            {/* If lead_type is applicant, then display position */}
                            {values?.lead_type?.value === "applicant" && (
                              <>
                                <Grid item xs={12} md={3}>
                                  <MDBox mb={2} className="mdsAutocompleteGrid">
                                    <Autocomplete
                                      value={values.position_id}
                                      size="small"
                                      name="position_id"
                                      onChange={(event, newValue) =>
                                        newValue
                                          ? setFieldValue(
                                              "position_id",
                                              newValue
                                            )
                                          : setFieldValue("position_id", "")
                                      }
                                      options={positionList}
                                      getOptionLabel={(positionList) =>
                                        positionList.setting_value || ""
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Position"
                                          InputLabelProps={{ shrink: true }}
                                        />
                                      )}
                                    />
                                    {ErrorDisp("position_id")}
                                  </MDBox>
                                </Grid>
                              </>
                            )}

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.industry_id}
                                  size="small"
                                  name="industry_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("industry_id", newValue)
                                      : setFieldValue("industry_id", "")
                                  }
                                  options={industries}
                                  getOptionLabel={(industries) =>
                                    industries.setting_value || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Industry"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("industry_id")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.priority}
                                  size="small"
                                  name="priority"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("priority", newValue)
                                      : setFieldValue("priority", "")
                                  }
                                  options={priorityOptions}
                                  getOptionLabel={(priorityOptions) =>
                                    priorityOptions.label || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Priority"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("priority")}
                              </MDBox>
                            </Grid>

                            {/* If lead_type is company, then display size_of_company, website, Contact Number and emailid */}
                            {values?.lead_type?.value === "company" && (
                              <>
                                {/* size_of_company  */}
                                <Grid item xs={12} md={3}>
                                  <MDBox className="mdsAutocompleteGrid">
                                    <Autocomplete
                                      value={values.size_of_company}
                                      size="small"
                                      name="size_of_company"
                                      onChange={(event, newValue) =>
                                        newValue
                                          ? setFieldValue(
                                              "size_of_company",
                                              newValue
                                            )
                                          : setFieldValue("size_of_company", "")
                                      }
                                      options={companySizes}
                                      getOptionLabel={(companySizes) =>
                                        companySizes.setting_value || ""
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Company Size"
                                          InputLabelProps={{ shrink: true }}
                                        />
                                      )}
                                    />
                                    {ErrorDisp("size_of_company")}
                                  </MDBox>
                                </Grid>
                                {/* website */}
                                <Grid item xs={12} md={3}>
                                  <MDBox mb={2}>
                                    <MDInput
                                      type="text"
                                      name="website"
                                      label="Website"
                                      fullWidth
                                      value={values.website}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {ErrorDisp("website")}
                                  </MDBox>
                                </Grid>
                                {/* Contact Number */}
                                <Grid item container xs={12} md={3} spacing={0}>
                                  <Grid item xs={6} md={6}>
                                    <MDBox className="mdsAutocompleteGrid">
                                      <Autocomplete
                                        value={values.contact_code_id}
                                        size="small"
                                        name="contact_code_id"
                                        onChange={(event, newValue) => {
                                          if (newValue) {
                                            setFieldValue(
                                              "contact_code_id",
                                              newValue
                                            );
                                          } else {
                                            formData.contact_code_id = "";
                                            setFieldValue(
                                              "contact_code_id",
                                              ""
                                            );
                                            setFieldError(
                                              "contact_code_id",
                                              "Please select valid code"
                                            );
                                          }
                                        }}
                                        options={countryList}
                                        getOptionLabel={(countryList) => {
                                          if (
                                            countryList.dial_code &&
                                            countryList.name
                                          ) {
                                            return (
                                              countryList.dial_code +
                                              " " +
                                              countryList.name
                                            );
                                          }
                                          return "";
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Country Code"
                                            InputLabelProps={{ shrink: true }}
                                          />
                                        )}
                                      />
                                      {ErrorDisp("contact_code_id")}
                                    </MDBox>
                                  </Grid>
                                  <Grid item xs={6} md={6}>
                                    <MDBox mb={2}>
                                      <MDInput
                                        name="contact_number"
                                        type="text"
                                        label="Contact Number"
                                        fullWidth
                                        value={values.contact_number}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      {ErrorDisp("contact_number")}
                                    </MDBox>
                                  </Grid>
                                </Grid>
                                {/* E-mail Id */}
                                <Grid item xs={12} md={3}>
                                  <MDBox mb={2}>
                                    <MDInput
                                      name="email_id"
                                      type="email"
                                      label="E-mail Id"
                                      fullWidth
                                      value={values.email_id}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {ErrorDisp("email_id")}
                                  </MDBox>
                                </Grid>
                              </>
                            )}

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.assigned_user_id}
                                  size="small"
                                  name="assigned_user_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue(
                                          "assigned_user_id",
                                          newValue
                                        )
                                      : setFieldValue("assigned_user_id", "")
                                  }
                                  options={usersList}
                                  getOptionLabel={(usersList) =>
                                    (usersList &&
                                      usersList.first_name +
                                        " " +
                                        usersList.last_name) ||
                                    ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Assigned User"
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(e) =>
                                        searchSpot("user", e.target.value)
                                      }
                                    />
                                  )}
                                />
                                {ErrorDisp("assigned_user_id")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="start_date"
                                  type="date"
                                  label="Start Date"
                                  fullWidth
                                  value={values.start_date}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("start_date")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="end_date"
                                  type="date"
                                  label="End Date"
                                  fullWidth
                                  value={values.end_date}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("end_date")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.status_id}
                                  size="small"
                                  name="status_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("status_id", newValue)
                                      : setFieldValue("status_id", "")
                                  }
                                  options={statusList}
                                  getOptionLabel={(statusList) =>
                                    statusList.setting_value || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Status"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("status_id")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <MDBox mb={2}>
                                <TextField
                                  id="outlined-multiline-static"
                                  label="Remarks"
                                  name="remarks"
                                  InputLabelProps={{ shrink: true }}
                                  multiline
                                  fullWidth
                                  rows={3}
                                  value={values.remarks}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("remarks")}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
            <MDBox mt={2} display="flex" alignItems="center">
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                type="submit"
                className="saveButton"
              >
                Save
              </MDButton>
            </MDBox>
          </Form>
        </Formik>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LeadData;
