// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import useSWR from "swr";
import { useEffect, useState } from "react";
//import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import { Button, DialogContent } from "@mui/material";
//import { settingDefault } from "utils/clientFunctions";
import { Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";

import { postData, doUpload } from "utils/clientFunctions";
import { useToasts } from "react-toast-notifications";

import { Link, useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../../src/assets/css/Custom.css";
import { useFormik } from "formik";

import { createTaskSchema } from "../components/schemas";
import { CardTopBoxBack } from "layouts/commonComponents/cardTopBoxBack";
import { updateData } from "utils/clientFunctions";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
function TaskData() {
  let { id } = useParams();
  const [companies, setCompanyList] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [usersList, setUserList] = useState([]);
  const [statusList, setstatusList] = useState([]);
  const { addToast } = useToasts();
  const navigate = useNavigate();
  //Start : For permission
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);
  if (id) {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("tskEdit")
      )
    ) {
      navigate("/task");
    }
  } else {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("tskCreate")
      )
    ) {
      navigate("/task");
    }
  }
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission
  const [formData, setFormData] = useState({
    name: "",
    reason_id: "",
    company_id: "",
    country_id: "",
    priority: "",
    assigned_user_id: "",
    start_date: "",
    end_date: "",
    status_id: "",
    remarks: "",
    upload_document: "",
  });

  const searchSpot = (value, key) => {
    liveSearchData({
      countryId: null,
      value,
      key,
      setCompanyList,
      setUserList,
    });
  };

  const getFetchData = async (settingUrl) => {
    return await fetchData(settingUrl);
  };

  //Get country info
  useEffect(() => {
    if (id) {
      const updateUrl = `task/${id}`;
      getFetchData(updateUrl).then((res) => {
        let {
          name,
          priority,
          start_date,
          end_date,
          remarks,
          reason_info,
          company_info,
          country_info,
          assigned_user_info,
          status_info,
          upload_document,
          created_user_info,
        } = res.data;
        let newPriority = {
          label: priority,
          value: priority,
        };
        setFormData({
          name: name,
          reason_id: reason_info,
          country_id: country_info,
          company_id: company_info,
          priority: newPriority,
          assigned_user_id: assigned_user_info,
          start_date: start_date,
          end_date: end_date,
          status_id: status_info,
          remarks: remarks,
          upload_document: upload_document || "",
        });
      });
    }
    const countryUrl = `country/list`;
    getFetchData(countryUrl).then((res) => {
      let countryList = res.data;
      setCountryList(countryList);
    });

    const reasonUrl = `setting/type/9`;
    getFetchData(reasonUrl).then((res) => {
      let reasonList = res.data;
      setReasons(reasonList);
    });

    const userUrl = `user/list?page=1`;
    getFetchData(userUrl).then((res) => {
      let userList = res.data;
      setUserList(userList);
    });

    const companyUrl = `company/list?page=1`;
    getFetchData(companyUrl).then((res) => {
      let companyList = res.data;
      setCompanyList(companyList);
    });

    const statusUrl = `setting/type/8`;
    getFetchData(statusUrl).then((res) => {
      let statusList = res.data;
      setstatusList(statusList);
    });
  }, []);

  const handleFormSubmit = async (data, resetForm) => {
    //Collect final Data
    let submitData = {};
    submitData = data;

    //Update below fields to send only ids
    submitData.reason_id = data.reason_id?.setting_id;
    submitData.country_id = data.country_id?.country_id;
    submitData.company_id = data.company_id?.company_id;

    submitData.priority = data.priority?.value;
    submitData.assigned_user_id = data.assigned_user_id?.user_id;
    submitData.status_id = data.status_id?.setting_id;
    const url = `task`;
    const response = id
      ? await updateData(`task/${id}`, submitData)
      : await postData(url, submitData);
    if (!response.status) {
      if (response.message) {
        addToast(response.message, { appearance: "error", autoDismiss: true });
      }
      if (response.errors) {
        let apiErrors = response.errors;
        for (let i = 0; i < apiErrors.length; i++) {
          let errorMessage = apiErrors[i].message;
          errorMessage = errorMessage.replace(/['"]+/g, "").replace(/_/g, " ");
          errorMessage =
            errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
          addToast(errorMessage, { appearance: "error", autoDismiss: true });
        }
      }
    } else {
      addToast("Saved Successfully", {
        appearance: "success",
        autoDismiss: true,
      });
      navigate(-1);
    }
  };

  const ErrorDisp = (name) => {
    console.log(errors?.[name]); // Use `errors?.[name]` to access the dynamic field name
    return (
      errors[name] &&
      touched[name] && ( // Use `errors[name]` instead of `errors.name`
        <p className="formError">{errors[name]}</p>
      )
    );
  };

  const handleFileChange = async (event) => {
    let name = event.target.name;
    let val = event.target.files[0];
    let uploadFile = { file: val };
    const url = `upload_any/do_upload`;
    const response = await doUpload(url, uploadFile);
    if (response.status && response.uploadedFile) {
      let filePath = response.uploadedFile.path;
      //setFormData({ ...formData, [name]: filePath });
      formData.upload_document = filePath;
      setFieldValue("upload_document", filePath);
    } else {
      formData.upload_document = "";
      setFieldValue("upload_document", "");
      setFieldError("upload_document", response.message);
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema: createTaskSchema,
    onSubmit: (values, { resetForm }) => {
      handleFormSubmit(values, resetForm);
    },
  });

  const priorityOptions = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Formik
          initialValues={formData}
          enableReinitialze={true}
          onSubmit={handleSubmit}
        >
          <Form>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <CardTopBoxBack title={id ? "Update Task" : "New Task"} />
                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={9}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="name"
                                  type="text"
                                  label="Name *"
                                  fullWidth
                                  value={values.name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("name")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.reason_id}
                                  size="small"
                                  name="reason_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("reason_id", newValue)
                                      : setFieldValue("reason_id", "")
                                  }
                                  options={reasons}
                                  getOptionLabel={(reasons) =>
                                    reasons.setting_value || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Reason *"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("reason_id")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.company_id}
                                  size="small"
                                  name="company_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("company_id", newValue)
                                      : setFieldValue("company_id", "")
                                  }
                                  options={companies}
                                  getOptionLabel={(companies) =>
                                    companies.name || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Company *"
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(e) =>
                                        searchSpot("company", e.target.value)
                                      }
                                    />
                                  )}
                                />
                                {ErrorDisp("company_id")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.country_id}
                                  size="small"
                                  name="country_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("country_id", newValue)
                                      : setFieldValue("country_id", "")
                                  }
                                  options={countryList}
                                  getOptionLabel={(countryList) =>
                                    countryList.name || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Country *"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("country_id")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.priority}
                                  size="small"
                                  name="priority"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("priority", newValue)
                                      : setFieldValue("priority", "")
                                  }
                                  options={priorityOptions}
                                  getOptionLabel={(priorityOptions) =>
                                    priorityOptions.label || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Priority *"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("priority")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.assigned_user_id}
                                  size="small"
                                  name="assigned_user_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue(
                                          "assigned_user_id",
                                          newValue
                                        )
                                      : setFieldValue("assigned_user_id", "")
                                  }
                                  options={usersList}
                                  getOptionLabel={(usersList) =>
                                    (usersList &&
                                      usersList.first_name +
                                        " " +
                                        usersList.last_name) ||
                                    ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Assigned To *"
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(e) =>
                                        searchSpot("user", e.target.value)
                                      }
                                    />
                                  )}
                                />
                                {ErrorDisp("assigned_user_id")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.status_id}
                                  size="small"
                                  name="status_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("status_id", newValue)
                                      : setFieldValue("status_id", "")
                                  }
                                  options={statusList}
                                  getOptionLabel={(statusList) =>
                                    statusList.setting_value || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Status *"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("status_id")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="start_date"
                                  type="date"
                                  label="Start Date *"
                                  fullWidth
                                  value={values.start_date}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("start_date")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="end_date"
                                  type="date"
                                  label="End Date *"
                                  fullWidth
                                  value={values.end_date}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("end_date")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox
                                mb={2}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  //margin: "0px 2px",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  component="label"
                                  fullWidth
                                  color="success"
                                >
                                  Upload Document
                                  <input
                                    type="file"
                                    name="upload_document"
                                    accept=".pdf, .xls, .xlsx, .doc, .docx, .jpg, .jpeg, .png"
                                    hidden
                                    onChange={(e) => handleFileChange(e)}
                                  />
                                </Button>
                                {values.upload_document && (
                                  <>
                                    <Link
                                      to={`${process.env.REACT_APP_URI_PATH}${values.upload_document}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="mdsViewFile"
                                    >
                                      <MDButton
                                        variant="gradient"
                                        color="primary"
                                        size="small"
                                        type="button"
                                      >
                                        View File
                                      </MDButton>
                                    </Link>
                                  </>
                                )}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <MDBox mb={2}>
                                <TextField
                                  id="outlined-multiline-static"
                                  label="Remarks *"
                                  name="remarks"
                                  InputLabelProps={{ shrink: true }}
                                  multiline
                                  fullWidth
                                  rows={3}
                                  value={values.remarks}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("remarks")}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
            <MDBox mt={2} display="flex" alignItems="center">
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                type="submit"
                className="saveButton"
              >
                Save
              </MDButton>
            </MDBox>
          </Form>
        </Formik>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TaskData;
