import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";

export const CardTopBoxAdd = ({ title, link, showAddButton = true }) => {
  return (
    <MDBox
      mx={2}
      mt={-5}
      py={1}
      px={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      variant="gradient"
      bgColor="info"
      borderRadius="lg"
      coloredShadow="info"
    >
      <MDTypography variant="h6" color="white">
        {title}
      </MDTypography>
      {showAddButton && (
        <Link to={link}>
          <MDButton variant="outlined" color="white" size="small">
            Add +
          </MDButton>
        </Link>
      )}
    </MDBox>
  );
};
